import './Sneakers.css';
import Doublewrite from '../components/Doublewrite';
import Sliderproduct from '../components/Sliderproduct';
import Productmenu from './pagescomponents/Productmenu';
import { useProductContext } from './ProductContext';
import {Link} from "react-router-dom";
import Productcomponents from '../components/Productcomponents';
import {useSpring, animated} from 'react-spring';
import React, { useState, useEffect} from 'react';
import New_product from '../components/New_product';


import adidascampus from './pagescomponents/images/adidas Originals Campus 00s.jpg';
import jordan4 from './pagescomponents/images/Air Jordan 4 Retro ‘‘Lightning 2021’’ trong.jpg'
import jordan1 from './pagescomponents/images/Air Jordan 4 Fire Red.jpg';
import jordan1h from '../components/immagini/“Yellow Toe” Air Jordan 1 High Still Expected to Release This Year.jpg'

import nikedunk from './pagescomponents/images/download (1).jpg'

function Sneakers (){


const { products } = useProductContext();

const scritte={firstwrite: 'OUR SNEAKERS', secondwrite: 'OUR SNEAKERS'}

    
const sneakersProducts = products.filter(product => product.categoria === 'Sneakers');

// Seleziona solo i primi 6 prodotti
const selectedProducts = sneakersProducts;




const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
useEffect(() => {
  window.scrollTo(0, 0);
}, []);



    return (

        <>
       <animated.div style={props}>

        <Doublewrite firstwrite={scritte.firstwrite} secondwrite={scritte.secondwrite} />

        <div className='sneakers_responsive'>

        <div className='mt-4'>
        <Productmenu menutitle='SELEZIONA QUELLO CHE CERCHI'/>
        </div>
        <New_product title="Last Sneakers" products={selectedProducts}/> {/* Passa i prodotti selezionati come props */}
        </div>

            <div className='mt-5'>
                <h2 className='h2graphics_sn text-center mb-2' style={{fontFamily: " 'Nosifer', 'sans-serif'"}}>in evidenza</h2>
                </div>
                <div className='d-md-flex justify-content-evenly align-items-center'>

                    <div className='d-column p-md-4'>
                    <img src={adidascampus} className='img-fluid mt-3' /> 

                    <div className='ms-5 mt-3'>
                    <h4 style={{ fontFamily: "'Anton', 'sans-serif'", fontSize: '23px',}}>Adidas Campus 00'</h4>
                  <Link to='Adidas' style={{textDecoration: 'none'}} >
                    <button type="button" class="btn btn-warning mt-2 mb-3 mb-md-0 mt-md-0" style={{fontFamily: "'Raleway', 'sans-serif'",
                     fontWeight: '900', fontSize: '20px',}}>Scopri ora</button>
                  </Link>
                    </div>
                    </div>
               
                
                    <div className='d-column p-md-4'>
               
                <img src={nikedunk} className='img-fluid mt-3' /> 
                <div className='ms-5 mt-3'>
                <h4 style={{fontFamily: "'Anton', 'sans-serif'", fontSize: '23px',}}>Nike Dunk Low</h4>
                <Link to='Nike' style={{textDecoration: 'none'}} >
                <button type="button" class="btn btn-warning mt-2 mb-3 mb-md-0 mt-md-0" style={{fontFamily: "'Raleway', 'sans-serif'",
                     fontWeight: '900', fontSize: '20px',}}>Scopri ora</button>   
                </Link>                
                </div>
                     </div>
                     <div className='d-column p-md-4'>
               
                <img src={jordan1h} className='img-fluid mt-3' /> 
                <div className='ms-5 mt-3'>
                <h4 style={{fontFamily: "'Anton', 'sans-serif'", fontSize: '23px',}}>Air Jordan 1</h4>
                <button type="button" class="btn btn-warning mt-2 mb-3 mb-md-0 mt-md-0" style={{fontFamily: "'Raleway', 'sans-serif'",
                     fontWeight: '900', fontSize: '20px',}}>In arrivo!</button>                   
                </div>

                

                     </div>
           </div>
           


            <div className='mt-5 ms-lg-5'>
        <h3 className='lastproducts text-start  mb-0'>ULTIMI PRODOTTI</h3>
        <div className="p-2 pt-0 d-flex flex-wrap align-items-center justify-content-evenly">
          {sneakersProducts.map((prod) => (
            <Link to={`/Abbigliamento/${prod.id}`} style={{ textDecoration: 'none', color: 'Black' }} key={prod.id}>
              <Productcomponents immagine={prod.immagine} brand={prod.brand} categoria={prod.categoria} prezzo={prod.prezzo} />
            </Link>
          ))}
        </div>
      </div>

</animated.div>
        </>
    )
}

export default Sneakers;