import React, { createContext, useContext, useState } from 'react';

import p1 from './pagescomponents/images/productimages/p1.png';
import p2 from './pagescomponents/images/productimages/p2.png';
import p3 from './pagescomponents/images/productimages/p3.png';
import p4 from './pagescomponents/images/productimages/p4.png';
import p5 from './pagescomponents/images/productimages/p5.png';
import p6 from './pagescomponents/images/productimages/p6.png';
import p7 from './pagescomponents/images/productimages/p7.png';
import p8 from './pagescomponents/images/productimages/p8.png';
import p9 from './pagescomponents/images/productimages/p9.png';
import p10 from './pagescomponents/images/productimages/p10.png';
import p11 from './pagescomponents/images/productimages/p11.png';
import p12 from './pagescomponents/images/productimages/p12.png';
import p13 from './pagescomponents/images/productimages/p13.png';
import p14 from './pagescomponents/images/productimages/p14.png';
import p15 from './pagescomponents/images/productimages/p15.png';
import p16 from './pagescomponents/images/productimages/p16.png';
import p17 from './pagescomponents/images/productimages/p17.png';
import p18 from './pagescomponents/images/productimages/p18.png';
import p19 from './pagescomponents/images/productimages/p19.png';
import p20 from './pagescomponents/images/productimages/p20.png';
import p21 from './pagescomponents/images/productimages/p21.png';
import p22 from './pagescomponents/images/productimages/p22.png';
import p23 from './pagescomponents/images/productimages/p23.png';
import p24 from './pagescomponents/images/productimages/p24.png';
import p25 from './pagescomponents/images/productimages/p25.png';
import p26 from './pagescomponents/images/productimages/p26.png';
import p27 from './pagescomponents/images/productimages/p27.png';
import p28 from './pagescomponents/images/productimages/p28.jpg';
import p29 from './pagescomponents/images/productimages/p29.jpg';
import p30 from './pagescomponents/images/productimages/p30.png';
import p31 from './pagescomponents/images/productimages/p31.png';
import p32 from './pagescomponents/images/productimages/p32.png';
import p33 from './pagescomponents/images/productimages/p33.png';
import p34 from './pagescomponents/images/productimages/p34.png';
import p35 from './pagescomponents/images/productimages/p35.png';
import p36 from './pagescomponents/images/productimages/p36.png';
import p37 from './pagescomponents/images/productimages/p37.png';
import p38 from './pagescomponents/images/productimages/p38.png';
import p39 from './pagescomponents/images/productimages/p39.png';
import p40 from './pagescomponents/images/productimages/p40.png';
import p41 from './pagescomponents/images/productimages/p41.png';
import p42 from './pagescomponents/images/productimages/p42.png';
import p43 from './pagescomponents/images/productimages/p43.png';
import p44 from './pagescomponents/images/productimages/p44.png';
import p45 from './pagescomponents/images/productimages/p45.png';
import p46 from './pagescomponents/images/productimages/p46.png';
import p47 from './pagescomponents/images/productimages/p47.jpg';
import p48 from './pagescomponents/images/productimages/p48.jpg';
import p49 from './pagescomponents/images/productimages/p49.png';
import p50 from './pagescomponents/images/productimages/p50.png';
import p51 from './pagescomponents/images/productimages/p51.png';
import p52 from './pagescomponents/images/productimages/p52.png';
import p53 from './pagescomponents/images/productimages/p53.png';
import p54 from './pagescomponents/images/productimages/p54.jpg';
import p55 from './pagescomponents/images/productimages/p55.jpg';
import p56 from './pagescomponents/images/productimages/p56.jpg';
import p57 from './pagescomponents/images/productimages/p57.jpg';
import p58 from './pagescomponents/images/productimages/p58.jpg';
import p59 from './pagescomponents/images/productimages/p59.png';
import p60 from './pagescomponents/images/productimages/p60.png';
import p61 from './pagescomponents/images/productimages/p61.png';
import p62 from './pagescomponents/images/productimages/p62.png';
import p63 from './pagescomponents/images/productimages/p63.png';
import p64 from './pagescomponents/images/productimages/p64.png';
import p65 from './pagescomponents/images/productimages/p65.png';
import p66 from './pagescomponents/images/productimages/p66.png';
import p67 from './pagescomponents/images/productimages/p67.png';
import p68 from './pagescomponents/images/productimages/p68.png';
import p69 from './pagescomponents/images/productimages/p69.png';
import p70 from './pagescomponents/images/productimages/p70.png';
import p71 from './pagescomponents/images/productimages/p71.png';
import p72 from './pagescomponents/images/productimages/p72.png';
import p73 from './pagescomponents/images/productimages/p73.png';
import p74 from './pagescomponents/images/productimages/p74.png';
import p75 from './pagescomponents/images/productimages/p75.png';
import p76 from './pagescomponents/images/productimages/p76.png';
import p77 from './pagescomponents/images/productimages/p77.png';
import p78 from './pagescomponents/images/productimages/p78.png';
import p79 from './pagescomponents/images/productimages/p79.png';
import p80 from './pagescomponents/images/productimages/p80.png';
import p81 from './pagescomponents/images/productimages/p81.png';
import p82 from './pagescomponents/images/productimages/p82.png';
import p83 from './pagescomponents/images/productimages/p83.png';
import p84 from './pagescomponents/images/productimages/p84.png';
import p85 from './pagescomponents/images/productimages/p85.png';
import p86 from './pagescomponents/images/productimages/p86.png';
import p87 from './pagescomponents/images/productimages/p87.png';
import p88 from './pagescomponents/images/productimages/p88.png';
import p89 from './pagescomponents/images/productimages/p89.png';
import p90 from './pagescomponents/images/productimages/p90.png';
import p91 from './pagescomponents/images/productimages/p91.png';
import p92 from './pagescomponents/images/productimages/p92.png';
import p93 from './pagescomponents/images/productimages/p93.png';
import p94 from './pagescomponents/images/productimages/p94.png';
import p95 from './pagescomponents/images/productimages/p95.png';
import p96 from './pagescomponents/images/productimages/p96.png';
import p97 from './pagescomponents/images/productimages/p97.png';
import p98 from './pagescomponents/images/productimages/p98.png';
import p99 from './pagescomponents/images/productimages/p99.png';
import p100 from './pagescomponents/images/productimages/p100.png';
import p101 from './pagescomponents/images/productimages/p101.png';
import p102 from './pagescomponents/images/productimages/p102.png';
import p103 from './pagescomponents/images/productimages/p103.png';
import p104 from './pagescomponents/images/productimages/p104.png';
import p105 from './pagescomponents/images/productimages/p105.png';
import p106 from './pagescomponents/images/productimages/p106.png';
import p107 from './pagescomponents/images/productimages/p107.png';
import p108 from './pagescomponents/images/productimages/p108.png';
import p109 from './pagescomponents/images/productimages/p109.png';
import p110 from './pagescomponents/images/productimages/p110.png';
import p111 from './pagescomponents/images/productimages/p111.png';
import p112 from './pagescomponents/images/productimages/p112.png';
import p113 from './pagescomponents/images/productimages/p113.png';
import p114 from './pagescomponents/images/productimages/p114.png';
import p115 from './pagescomponents/images/productimages/p115.png';
import p116 from './pagescomponents/images/productimages/p116.png';
import p117 from './pagescomponents/images/productimages/p117.png';
import p118 from './pagescomponents/images/productimages/p118.png';
import p119 from './pagescomponents/images/productimages/p119.png';
import p120 from './pagescomponents/images/productimages/p120.png';
import p121 from './pagescomponents/images/productimages/p121.png';
import p122 from './pagescomponents/images/productimages/p122.png';
import p123 from './pagescomponents/images/productimages/p123.png';
import p124 from './pagescomponents/images/productimages/p124.png';
import p125 from './pagescomponents/images/productimages/p125.png';
import p126 from './pagescomponents/images/productimages/p126.png';
import p127 from './pagescomponents/images/productimages/p127.png';
import p128 from './pagescomponents/images/productimages/p128.png';
import p129 from './pagescomponents/images/productimages/p129.png';
import p130 from './pagescomponents/images/productimages/p130.png';
import p131 from './pagescomponents/images/productimages/p131.png';
import p132 from './pagescomponents/images/productimages/p132.png';
import p133 from './pagescomponents/images/productimages/p133.png';
import p134 from './pagescomponents/images/productimages/p134.png';
import p135 from './pagescomponents/images/productimages/p135.png';
import p136 from './pagescomponents/images/productimages/p136.png';
import p137 from './pagescomponents/images/productimages/p137.png';
import p138 from './pagescomponents/images/productimages/p138.png';
import p139 from './pagescomponents/images/productimages/p139.png';
import p140 from './pagescomponents/images/productimages/p140.png';
import p141 from './pagescomponents/images/productimages/p141.png';
import p142 from './pagescomponents/images/productimages/p142.png';
import p143 from './pagescomponents/images/productimages/p143.png';
import p144 from './pagescomponents/images/productimages/p144.png';
import p145 from './pagescomponents/images/productimages/p145.png';
import p146 from './pagescomponents/images/productimages/p146.png';
import p147 from './pagescomponents/images/productimages/p147.png';
import p148 from './pagescomponents/images/productimages/p148.png';
import p149 from './pagescomponents/images/productimages/p149.png';
import p150 from './pagescomponents/images/productimages/p150.png';
import p151 from './pagescomponents/images/productimages/p151.png';
import p152 from './pagescomponents/images/productimages/p152.png';
import p153 from './pagescomponents/images/productimages/p153.png';
import p154 from './pagescomponents/images/productimages/p154.png';
import p155 from './pagescomponents/images/productimages/p155.png';
import p156 from './pagescomponents/images/productimages/p156.png';
import p157 from './pagescomponents/images/productimages/p157.png';
import p158 from './pagescomponents/images/productimages/p158.png';
import p159 from './pagescomponents/images/productimages/p159.png';
import p160 from './pagescomponents/images/productimages/p160.png';
import p161 from './pagescomponents/images/productimages/p161.png';
import p162 from './pagescomponents/images/productimages/p162.png';
import p163 from './pagescomponents/images/productimages/p163.png';
import p164 from './pagescomponents/images/productimages/p164.png';
import p165 from './pagescomponents/images/productimages/p165.png';
import p166 from './pagescomponents/images/productimages/p166.png';
import p167 from './pagescomponents/images/productimages/p167.png';
import p168 from './pagescomponents/images/productimages/p168.png';
import p169 from './pagescomponents/images/productimages/p169.png';
import p170 from './pagescomponents/images/productimages/p170.png';
import p171 from './pagescomponents/images/productimages/p171.png';
import p172 from './pagescomponents/images/productimages/p172.png';
import p173 from './pagescomponents/images/productimages/p173.png';
import p174 from './pagescomponents/images/productimages/p174.png';
import p175 from './pagescomponents/images/productimages/p175.png';
import p176 from './pagescomponents/images/productimages/p176.png';
import p177 from './pagescomponents/images/productimages/p177.png';
import p178 from './pagescomponents/images/productimages/p178.png';
import p179 from './pagescomponents/images/productimages/p179.png';
import p180 from './pagescomponents/images/productimages/p180.png';
import p181 from './pagescomponents/images/productimages/p181.png';
import p182 from './pagescomponents/images/productimages/p182.png';
import p183 from './pagescomponents/images/productimages/p183.png';
import p184 from './pagescomponents/images/productimages/p184.png';
import p185 from './pagescomponents/images/productimages/p185.png';
import p186 from './pagescomponents/images/productimages/p186.png';
import p187 from './pagescomponents/images/productimages/p187.png';
import p188 from './pagescomponents/images/productimages/p188.png';
import p189 from './pagescomponents/images/productimages/p189.png';
import p190 from './pagescomponents/images/productimages/p190.png';
import p191 from './pagescomponents/images/productimages/p191.png';
import p192 from './pagescomponents/images/productimages/p192.png';
import p193 from './pagescomponents/images/productimages/p193.png';
import p194 from './pagescomponents/images/productimages/p194.png';
import p195 from './pagescomponents/images/productimages/p195.png';
import p196 from './pagescomponents/images/productimages/p196.png';
import p197 from './pagescomponents/images/productimages/p197.png';
import p198 from './pagescomponents/images/productimages/p198.png';
import p199 from './pagescomponents/images/productimages/p199.png';
import p200 from './pagescomponents/images/productimages/p200.png';
import p201 from './pagescomponents/images/productimages/p201.png';
import p202 from './pagescomponents/images/productimages/p202.png';
import p203 from './pagescomponents/images/productimages/p203.png';
import p204 from './pagescomponents/images/productimages/p204.png';
import p205 from './pagescomponents/images/productimages/p205.png';
import p206 from './pagescomponents/images/productimages/p206.png';
import p207 from './pagescomponents/images/productimages/p207.png';
import p208 from './pagescomponents/images/productimages/p208.png';
import p209 from './pagescomponents/images/productimages/p209.png';
import p210 from './pagescomponents/images/productimages/p210.png';



import p1_dietro from './pagescomponents/images/productimages/p1_dietro.png';
import p2_dietro from './pagescomponents/images/productimages/p2_dietro.png';
import p3_dietro from './pagescomponents/images/productimages/p3_dietro.png';
import p4_dietro from './pagescomponents/images/productimages/p4_dietro.png';
import p5_dietro from './pagescomponents/images/productimages/p5_dietro.png';
import p6_dietro from './pagescomponents/images/productimages/p6_dietro.png';
import p7_dietro from './pagescomponents/images/productimages/p7_dietro.png';
import p8_dietro from './pagescomponents/images/productimages/p8_dietro.png';
import p9_dietro from './pagescomponents/images/productimages/p9_dietro.png';
import p10_dietro from './pagescomponents/images/productimages/p10_dietro.png';
import p11_dietro from './pagescomponents/images/productimages/p11_dietro.png';
import p12_dietro from './pagescomponents/images/productimages/p12_dietro.png';
import p13_dietro from './pagescomponents/images/productimages/p13_dietro.png';
import p14_dietro from './pagescomponents/images/productimages/p14_dietro.png';
import p15_dietro from './pagescomponents/images/productimages/p15_dietro.png';
import p16_dietro from './pagescomponents/images/productimages/p16_dietro.png';
import p17_dietro from './pagescomponents/images/productimages/p17_dietro.png';
import p18_dietro from './pagescomponents/images/productimages/p18_dietro.png';
import p19_dietro from './pagescomponents/images/productimages/p19_dietro.png';
import p20_dietro from './pagescomponents/images/productimages/p20_dietro.png';
import p21_dietro from './pagescomponents/images/productimages/p21_dietro.png';
import p22_dietro from './pagescomponents/images/productimages/p22_dietro.png';
import p23_dietro from './pagescomponents/images/productimages/p23_dietro.png';
import p24_dietro from './pagescomponents/images/productimages/p24_dietro.png';
import p25_dietro from './pagescomponents/images/productimages/p25_dietro.png';
import p26_dietro from './pagescomponents/images/productimages/p26_dietro.png';
import p27_dietro from './pagescomponents/images/productimages/p27_dietro.png';
import p28_dietro from './pagescomponents/images/productimages/p28_dietro.jpg';
import p29_dietro from './pagescomponents/images/productimages/p29_dietro.jpg';
import p30_dietro from './pagescomponents/images/productimages/p30_dietro.png';
import p31_dietro from './pagescomponents/images/productimages/p31_dietro.png';
import p32_dietro from './pagescomponents/images/productimages/p32_dietro.png';
import p33_dietro from './pagescomponents/images/productimages/p33_dietro.png';
import p34_dietro from './pagescomponents/images/productimages/p34_dietro.png';
import p35_dietro from './pagescomponents/images/productimages/p35_dietro.png';
import p36_dietro from './pagescomponents/images/productimages/p36_dietro.png';
import p37_dietro from './pagescomponents/images/productimages/p37_dietro.png';
import p38_dietro from './pagescomponents/images/productimages/p38_dietro.png';
import p39_dietro from './pagescomponents/images/productimages/p39_dietro.png';
import p40_dietro from './pagescomponents/images/productimages/p40_dietro.png';
import p41_dietro from './pagescomponents/images/productimages/p41_dietro.png';
import p42_dietro from './pagescomponents/images/productimages/p42_dietro.png';
import p43_dietro from './pagescomponents/images/productimages/p43_dietro.png';
import p44_dietro from './pagescomponents/images/productimages/p44_dietro.png';
import p45_dietro from './pagescomponents/images/productimages/p45_dietro.png';
import p46_dietro from './pagescomponents/images/productimages/p46_dietro.png';
import p47_dietro from './pagescomponents/images/productimages/p47_dietro.jpg';
import p48_dietro from './pagescomponents/images/productimages/p48_dietro.jpg';
import p49_dietro from './pagescomponents/images/productimages/p49_dietro.png';
import p50_dietro from './pagescomponents/images/productimages/p50_dietro.png';
import p51_dietro from './pagescomponents/images/productimages/p51_dietro.png';
import p52_dietro from './pagescomponents/images/productimages/p52_dietro.png';
import p53_dietro from './pagescomponents/images/productimages/p53_dietro.png';
import p54_dietro from './pagescomponents/images/productimages/p54_dietro.jpg';
import p55_dietro from './pagescomponents/images/productimages/p55_dietro.jpg';
import p56_dietro from './pagescomponents/images/productimages/p56_dietro.jpg';
import p57_dietro from './pagescomponents/images/productimages/p57_dietro.jpg';
import p58_dietro from './pagescomponents/images/productimages/p58_dietro.jpg';
import p59_dietro from './pagescomponents/images/productimages/p59_dietro.png';
import p60_dietro from './pagescomponents/images/productimages/p60_dietro.png';
import p61_dietro from './pagescomponents/images/productimages/p61_dietro.png';
import p62_dietro from './pagescomponents/images/productimages/p62_dietro.png';
import p63_dietro from './pagescomponents/images/productimages/p63_dietro.png';
import p64_dietro from './pagescomponents/images/productimages/p64_dietro.png';
import p65_dietro from './pagescomponents/images/productimages/p65_dietro.png';
import p66_dietro from './pagescomponents/images/productimages/p66_dietro.png';
import p67_dietro from './pagescomponents/images/productimages/p67_dietro.png';
import p68_dietro from './pagescomponents/images/productimages/p68_dietro.png';
import p69_dietro from './pagescomponents/images/productimages/p69_dietro.png';
import p70_dietro from './pagescomponents/images/productimages/p70_dietro.png';
import p71_dietro from './pagescomponents/images/productimages/p71_dietro.jpg';
import p72_dietro from './pagescomponents/images/productimages/p72_dietro.jpg';
import p73_dietro from './pagescomponents/images/productimages/p73_dietro.jpg';
import p74_dietro from './pagescomponents/images/productimages/p74_dietro.jpg';
import p75_dietro from './pagescomponents/images/productimages/p75_dietro.png';
import p76_dietro from './pagescomponents/images/productimages/p76_dietro.png';
import p77_dietro from './pagescomponents/images/productimages/p77_dietro.png';
import p78_dietro from './pagescomponents/images/productimages/p78_dietro.png';
import p79_dietro from './pagescomponents/images/productimages/p79_dietro.png';
import p80_dietro from './pagescomponents/images/productimages/p80_dietro.png';
import p81_dietro from './pagescomponents/images/productimages/p81_dietro.png';
import p82_dietro from './pagescomponents/images/productimages/p82_dietro.png';
import p83_dietro from './pagescomponents/images/productimages/p83_dietro.png';
import p84_dietro from './pagescomponents/images/productimages/p84_dietro.png';
import p85_dietro from './pagescomponents/images/productimages/p85_dietro.png';
import p86_dietro from './pagescomponents/images/productimages/p86_dietro.png';
import p87_dietro from './pagescomponents/images/productimages/p87_dietro.png';
import p88_dietro from './pagescomponents/images/productimages/p88_dietro.png';
import p89_dietro from './pagescomponents/images/productimages/p89_dietro.png';
import p90_dietro from './pagescomponents/images/productimages/p90_dietro.png';
import p91_dietro from './pagescomponents/images/productimages/p91_dietro.png';
import p92_dietro from './pagescomponents/images/productimages/p92_dietro.png';
import p93_dietro from './pagescomponents/images/productimages/p93_dietro.png';
import p94_dietro from './pagescomponents/images/productimages/p94_dietro.png';
import p95_dietro from './pagescomponents/images/productimages/p95_dietro.png';
import p96_dietro from './pagescomponents/images/productimages/p96_dietro.png';
import p97_dietro from './pagescomponents/images/productimages/p97_dietro.png';
import p98_dietro from './pagescomponents/images/productimages/p98_dietro.png';
import p99_dietro from './pagescomponents/images/productimages/p99_dietro.png';
import p100_dietro from './pagescomponents/images/productimages/p100_dietro.png';
import p101_dietro from './pagescomponents/images/productimages/p101_dietro.png';
import p102_dietro from './pagescomponents/images/productimages/p102_dietro.png';
import p103_dietro from './pagescomponents/images/productimages/p103_dietro.png';
import p104_dietro from './pagescomponents/images/productimages/p104_dietro.png';
import p105_dietro from './pagescomponents/images/productimages/p105_dietro.png';
import p106_dietro from './pagescomponents/images/productimages/p106_dietro.png';
import p107_dietro from './pagescomponents/images/productimages/p107_dietro.png';
import p108_dietro from './pagescomponents/images/productimages/p108_dietro.png';
import p109_dietro from './pagescomponents/images/productimages/p109_dietro.png';
import p110_dietro from './pagescomponents/images/productimages/p110_dietro.png';
import p111_dietro from './pagescomponents/images/productimages/p111_dietro.png';
import p112_dietro from './pagescomponents/images/productimages/p112_dietro.png';
import p113_dietro from './pagescomponents/images/productimages/p113_dietro.png';
import p114_dietro from './pagescomponents/images/productimages/p114_dietro.png';
import p115_dietro from './pagescomponents/images/productimages/p115_dietro.png';
import p116_dietro from './pagescomponents/images/productimages/p116_dietro.png';
import p117_dietro from './pagescomponents/images/productimages/p117_dietro.png';
import p118_dietro from './pagescomponents/images/productimages/p118_dietro.png';
import p119_dietro from './pagescomponents/images/productimages/p119_dietro.png';
import p120_dietro from './pagescomponents/images/productimages/p120_dietro.png';
import p121_dietro from './pagescomponents/images/productimages/p121_dietro.png';
import p122_dietro from './pagescomponents/images/productimages/p122_dietro.png';
import p123_dietro from './pagescomponents/images/productimages/p123_dietro.png';
import p124_dietro from './pagescomponents/images/productimages/p124_dietro.png';
import p125_dietro from './pagescomponents/images/productimages/p125_dietro.png';
import p126_dietro from './pagescomponents/images/productimages/p126_dietro.png';
import p127_dietro from './pagescomponents/images/productimages/p127_dietro.png';
import p128_dietro from './pagescomponents/images/productimages/p128_dietro.png';
import p129_dietro from './pagescomponents/images/productimages/p129_dietro.png';
import p130_dietro from './pagescomponents/images/productimages/p130_dietro.png';
import p131_dietro from './pagescomponents/images/productimages/p131_dietro.png';
import p132_dietro from './pagescomponents/images/productimages/p132_dietro.png';
import p133_dietro from './pagescomponents/images/productimages/p133_dietro.png';
import p134_dietro from './pagescomponents/images/productimages/p134_dietro.png';
import p135_dietro from './pagescomponents/images/productimages/p135_dietro.png';
import p136_dietro from './pagescomponents/images/productimages/p136_dietro.png';
import p137_dietro from './pagescomponents/images/productimages/p137_dietro.png';
import p138_dietro from './pagescomponents/images/productimages/p138_dietro.png';
import p139_dietro from './pagescomponents/images/productimages/p139_dietro.png';
import p140_dietro from './pagescomponents/images/productimages/p140_dietro.png';
import p141_dietro from './pagescomponents/images/productimages/p141_dietro.png';
import p142_dietro from './pagescomponents/images/productimages/p142_dietro.png';
import p143_dietro from './pagescomponents/images/productimages/p143_dietro.png';
import p144_dietro from './pagescomponents/images/productimages/p144_dietro.png';
import p145_dietro from './pagescomponents/images/productimages/p145_dietro.png';
import p146_dietro from './pagescomponents/images/productimages/p146_dietro.png';
import p147_dietro from './pagescomponents/images/productimages/p147_dietro.png';
import p148_dietro from './pagescomponents/images/productimages/p148_dietro.png';
import p149_dietro from './pagescomponents/images/productimages/p149_dietro.png';
import p150_dietro from './pagescomponents/images/productimages/p150_dietro.png';
import p151_dietro from './pagescomponents/images/productimages/p151_dietro.png';
import p152_dietro from './pagescomponents/images/productimages/p152_dietro.png';
import p153_dietro from './pagescomponents/images/productimages/p153_dietro.png';
import p154_dietro from './pagescomponents/images/productimages/p154_dietro.png';
import p155_dietro from './pagescomponents/images/productimages/p155_dietro.png';
import p156_dietro from './pagescomponents/images/productimages/p156_dietro.png';
import p157_dietro from './pagescomponents/images/productimages/p157_dietro.png';
import p158_dietro from './pagescomponents/images/productimages/p158_dietro.png';
import p159_dietro from './pagescomponents/images/productimages/p159_dietro.png';
import p160_dietro from './pagescomponents/images/productimages/p160_dietro.png';
import p161_dietro from './pagescomponents/images/productimages/p161_dietro.png';
import p162_dietro from './pagescomponents/images/productimages/p162_dietro.png';
import p163_dietro from './pagescomponents/images/productimages/p163_dietro.png';
import p164_dietro from './pagescomponents/images/productimages/p164_dietro.png';
import p165_dietro from './pagescomponents/images/productimages/p165_dietro.png';
import p166_dietro from './pagescomponents/images/productimages/p166_dietro.png';
import p167_dietro from './pagescomponents/images/productimages/p167_dietro.png';
import p168_dietro from './pagescomponents/images/productimages/p168_dietro.png';
import p169_dietro from './pagescomponents/images/productimages/p169_dietro.png';
import p170_dietro from './pagescomponents/images/productimages/p170_dietro.png';
import p171_dietro from './pagescomponents/images/productimages/p171_dietro.png';
import p172_dietro from './pagescomponents/images/productimages/p172_dietro.png';
import p173_dietro from './pagescomponents/images/productimages/p173_dietro.png';
import p174_dietro from './pagescomponents/images/productimages/p174_dietro.png';
import p175_dietro from './pagescomponents/images/productimages/p175_dietro.png';
import p176_dietro from './pagescomponents/images/productimages/p176_dietro.png';
import p177_dietro from './pagescomponents/images/productimages/p177_dietro.png';
import p178_dietro from './pagescomponents/images/productimages/p178_dietro.png';
import p179_dietro from './pagescomponents/images/productimages/p179_dietro.png';
import p180_dietro from './pagescomponents/images/productimages/p180_dietro.png';
import p181_dietro from './pagescomponents/images/productimages/p181_dietro.png';
import p182_dietro from './pagescomponents/images/productimages/p182_dietro.png';
import p183_dietro from './pagescomponents/images/productimages/p183_dietro.png';
import p184_dietro from './pagescomponents/images/productimages/p184_dietro.png';
import p185_dietro from './pagescomponents/images/productimages/p185_dietro.png';
import p186_dietro from './pagescomponents/images/productimages/p186_dietro.png';
import p187_dietro from './pagescomponents/images/productimages/p187_dietro.png';
import p188_dietro from './pagescomponents/images/productimages/p188_dietro.png';
import p189_dietro from './pagescomponents/images/productimages/p189_dietro.png';
import p190_dietro from './pagescomponents/images/productimages/p190_dietro.png';
import p191_dietro from './pagescomponents/images/productimages/p191_dietro.png';
import p192_dietro from './pagescomponents/images/productimages/p192_dietro.png';
import p193_dietro from './pagescomponents/images/productimages/p193_dietro.png';
import p194_dietro from './pagescomponents/images/productimages/p194_dietro.png';
import p195_dietro from './pagescomponents/images/productimages/p195_dietro.png';
import p196_dietro from './pagescomponents/images/productimages/p196_dietro.png';
import p197_dietro from './pagescomponents/images/productimages/p197_dietro.png';
import p198_dietro from './pagescomponents/images/productimages/p198_dietro.png';
import p199_dietro from './pagescomponents/images/productimages/p199_dietro.png';
import p200_dietro from './pagescomponents/images/productimages/p200_dietro.png';
import p201_dietro from './pagescomponents/images/productimages/p201_dietro.png';
import p202_dietro from './pagescomponents/images/productimages/p202_dietro.png';
import p203_dietro from './pagescomponents/images/productimages/p203_dietro.png';
import p204_dietro from './pagescomponents/images/productimages/p204_dietro.png';
import p205_dietro from './pagescomponents/images/productimages/p205_dietro.png';
import p206_dietro from './pagescomponents/images/productimages/p206_dietro.png';
import p207_dietro from './pagescomponents/images/productimages/p207_dietro.png';
import p208_dietro from './pagescomponents/images/productimages/p208_dietro.png';
import p209_dietro from './pagescomponents/images/productimages/p209_dietro.png';
import p210_dietro from './pagescomponents/images/productimages/p210_dietro.png';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([
    {brand: 'The North Face', immagine: p1, prezzo: '129,99', categoria: 'Giacca invernale', immaginedietro: p1_dietro, codice_prodotto: '91901', taglie: ['S', 'M', 'L', 'XL', 'XL'], modello: '1996 Nupste Edition', trend:true},
    {brand: 'Off White', immagine: p2, prezzo: '89,99', categoria: 'Giacca', modello: 'NBA x Off-White', trend:true, immaginedietro: p2_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Bape', immagine: p3, prezzo: '79,99', categoria: 'Giacca', immaginedietro: p3_dietro, taglie: ['M', 'L', 'XL']},
    {brand: 'Travis Scott', immagine: p4, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p4_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Anti Social', immagine: p5, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p5_dietro, taglie: ['S', 'M', 'L', 'XL', 'XXL']}  ,
    {brand: 'Anti Social', immagine: p6, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p6_dietro, taglie: ['S', 'M', 'L', 'XL', 'XXL']},
    {brand: 'The North Face', immagine: p7, prezzo: '59,99', categoria: 'Giacca invernale', immaginedietro: p7_dietro, taglie: ['XS', 'S', 'M', 'L', 'XL', 'XXL']},
    {brand: 'The North Face', immagine: p8, prezzo: '59,99', categoria: 'Giacca invernale', immaginedietro: p8_dietro, taglie: ['XS', 'S', 'M', 'L', 'XL', 'XXL']},
    {brand: 'Moncler', immagine: p9, prezzo: '59,99', categoria: 'Giacca invernale', immaginedietro: p9_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Moncler', immagine: p10, prezzo: '59,99', categoria: 'Giacca invernale', immaginedietro: p10_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Stussy', immagine: p11, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p11_dietro, taglie: ['M', 'L', 'XL', 'XXL']},
    {brand: 'Nike', immagine: p12, prezzo: '74,99', categoria: 'Giacca', immaginedietro: p12_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Nike', immagine: p13, prezzo: '64,99', categoria: 'Pants', immaginedietro: p13_dietro, taglie: ['S', 'M', 'L', 'XL']},
    {brand: 'Nike', immagine: p14, prezzo: '64,99', categoria: 'Felpa', immaginedietro: p14_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL']},
    {brand: 'Nike', immagine: p15, prezzo: '69,99', categoria: 'Giacca', immaginedietro: p15_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Reverse Jacket'},
    {brand: 'Nike', immagine: p16, prezzo: '69,99', categoria: 'Giacca', immaginedietro: p16_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Reverse Jacket'},
    {brand: 'Nike', immagine: p17, prezzo: '69,99', categoria: 'Giacca', immaginedietro: p17_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Furry Jacket'},
    {brand: 'Nike', immagine: p18, prezzo: '69,99', categoria: 'Giacca', immaginedietro: p18_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Furry Jacket'},
    {brand: 'Nike', immagine: p19, prezzo: '69,99', categoria: 'Giacca', immaginedietro: p19_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Furry Jacket'},
    {brand: 'Nike', immagine: p20, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p20_dietro, taglie:[ 'M', 'L', 'XL'], modello: 'Nike Jacket'},
    {brand: 'Jordan', immagine: p21, prezzo: '64,99', categoria: 'Giacca', immaginedietro: p21_dietro, taglie:[ 'M', 'L', 'XL', '2XL', '3XL'], modello: 'Jordan Jacket'},
    {brand: 'Jordan', immagine: p22, prezzo: '64,99', categoria: 'Giacca', immaginedietro: p22_dietro, taglie:[ 'M', 'L', 'XL' ,'2XL', '3XL'], modello: 'Jordan Jacket'},
    {brand: 'Jordan', immagine: p23, prezzo: '64,99', categoria: 'Giacca', immaginedietro: p23_dietro, taglie:[ 'M', 'L', 'XL' ,'2XL', '3XL'], modello: 'Jordan Jacket'},
    {brand: 'Antisocial', immagine: p24, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p24_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Antisocial Club'},
    {brand: 'Gucci', immagine: p25, prezzo: '59,99', categoria: 'Maglione', immaginedietro: p25_dietro, taglie:['XS','S', 'M', 'L'], modello: 'Gucci Sweater'},
    {brand: 'Gucci', immagine: p26, prezzo: '59,99', categoria: 'Maglione', immaginedietro: p26_dietro, taglie:['XS','S', 'M', 'L'], modello: 'Gucci Sweater'},
    {brand: 'Gucci', immagine: p27, prezzo: '54,99', categoria: 'Giacca', immaginedietro: p27_dietro, taglie:['M','L', 'XL', 'XXL'], modello: 'PSG Jacket'},
    {brand: 'Gucci', immagine: p28, prezzo: '49,99', categoria: 'Giacca', immaginedietro: p28_dietro, taglie:['M','L', 'XL', 'XXL'], modello: 'PSG Pants'},
    {brand: 'The North Face', immagine: p29, prezzo: '89,99', categoria: 'Giacca', immaginedietro: p29_dietro, taglie:['S','M', 'L', 'XL'], modello: 'TNF x Supreme Jacket'},
    {brand: 'The North Face', immagine: p30, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p30_dietro, taglie:[ 'M', 'L', 'XL', 'XXL'], modello: 'Never stop exploring'},
    {brand: 'The North Face', immagine: p31, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p31_dietro, taglie:[ 'M', 'L', 'XL', 'XXL'], modello: 'Never stop exploring'},
    {brand: 'Ralph Lauren', immagine: p32, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p32_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p33, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p33_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p34, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p34_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p35, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p35_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p36, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p36_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p37, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p37_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Ralph Lauren', immagine: p38, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p38_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Ralph Lauren Sweater'},
    {brand: 'Travis Scott', immagine: p39, prezzo: '49,99', categoria: 'Felpa', immaginedietro: p39_dietro, taglie:['S', 'M', 'L', 'XL', 'XXL'], modello: 'Travis Scott Hoodie'},
    {brand: 'Stussy', immagine: p40, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p40_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Stussy Hoodie'},
    {brand: 'Stussy', immagine: p41, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p41_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Stussy Hoodie'},
    {brand: 'Stussy', immagine: p42, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p42_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Stussy Hoodie'},
    {brand: 'Stussy', immagine: p43, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p43_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Stussy Hoodie'},
    {brand: 'Balenciaga', immagine: p44, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p44_dietro, taglie:['46', '48', '50', '52'], modello: 'Balenciaga Jacket'},
    {brand: 'Supreme', immagine: p45, prezzo: '69,99', categoria: 'Giacca invernale', immaginedietro: p45_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Supreme Jacket'},
    {brand: 'Supreme', immagine: p46, prezzo: '69,99', categoria: 'Giacca invernale', immaginedietro: p46_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Supreme Jacket'},
    {brand: 'Supreme', immagine: p47, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p47_dietro, taglie:['S', 'M', 'L', 'XL', '2XL', '3XL'], modello: 'Adidas Jacket'},
    {brand: 'Supreme', immagine: p48, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p48_dietro, taglie:['S', 'M', 'L', 'XL', '2XL', '3XL'], modello: 'Adidas Jacket'},
    {brand: 'Chrome', immagine: p49, prezzo: '49,99', categoria: 'Felpa', immaginedietro: p49_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Chroome Hoodie'},
    {brand: 'Chrome', immagine: p50, prezzo: '49,99', categoria: 'Felpa', immaginedietro: p50_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Chroome Hoodie'},
    {brand: 'Chrome', immagine: p51, prezzo: '49,99', categoria: 'Felpa', immaginedietro: p51_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Chroome Hoodie'},
    {brand: 'Chrome', immagine: p52, prezzo: '49,99', categoria: 'Felpa', immaginedietro: p52_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Chrome Hoodie'},
    {brand: 'Needles', immagine: p53, prezzo: '59,99', categoria: 'Pants', immaginedietro: p53_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Needles Pants'},
    {brand: 'Chrome', immagine: p54, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p54_dietro, taglie:['M', 'L', 'XL','XXL'], modello: 'Chrome Hoodie'},
    {brand: 'Chrome', immagine: p55, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p55_dietro, taglie:['M', 'L', 'XL','XXL'], modello: 'Chrome Hoodie', trend: true},
    {brand: 'Chrome', immagine: p56, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p56_dietro, taglie:['M', 'L', 'XL','XXL'], modello: 'Chrome Hoodie'},
    {brand: 'Chrome', immagine: p57, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p57_dietro, taglie:['M', 'L', 'XL','XXL'], modello: 'Chrome Hoodie'},
    {brand: 'Lacoste', immagine: p58, prezzo: '54,99', categoria: 'Maglione', immaginedietro: p58_dietro, taglie:['XS', 'S', 'M', 'L', 'XL',], modello: 'Lacoste Sweater'},
    {brand: 'Lacoste', immagine: p59, prezzo: '54,99', categoria: 'Maglione', immaginedietro: p59_dietro, taglie:['XS', 'S', 'M', 'L', 'XL',], modello: 'Lacoste Sweater'},
    {brand: 'Lacoste', immagine: p60, prezzo: '54,99', categoria: 'Maglione', immaginedietro: p60_dietro, taglie:['XS', 'S', 'M', 'L', 'XL',], modello: 'Lacoste Sweater'},
    {brand: 'Lacoste', immagine: p61, prezzo: '54,99', categoria: 'Maglione', immaginedietro: p61_dietro, taglie:['XS', 'S', 'M', 'L', 'XL',], modello: 'Lacoste Sweater'},
    {brand: 'Levis', immagine: p62, prezzo: '49,99', categoria: 'Pants', immaginedietro: p62_dietro, taglie:['30', '31', '32', '33', '34', '36', '38'], modello: 'Levis Jeans'},
    {brand: 'Ralph Lauren', immagine: p63, prezzo: '49,99', categoria: 'Pants', immaginedietro: p63_dietro, taglie:['30', '31', '32', '33', '34', '36'], modello: 'Ralph Lauren Jeans'},
    {brand: 'Carhartt', immagine: p64, prezzo: '74,99', categoria: 'Giacca invernale', immaginedietro: p64_dietro, taglie:['L', 'XL', 'XXL',], modello: 'Carhartt Jacket'},
    {brand: 'Carhartt', immagine: p65, prezzo: '74,99', categoria: 'Giacca invernale', immaginedietro: p65_dietro, taglie:['L', 'XL', 'XXL',], modello: 'Carhartt Jacket'},
    {brand: 'Gucci', immagine: p66, prezzo: '69,99', categoria: 'Felpa', immaginedietro: p66_dietro, taglie:['XS','S', 'M', 'L',], modello: 'Gucci Hoodie', trend: true},
    {brand: 'Ralph Lauren', immagine: p67, prezzo: '59,99', categoria: 'Pants', immaginedietro: p67_dietro, taglie:['M', 'L', 'XL', 'XXL',], modello: 'Ralph Lauren Pants'},
    {brand: 'Ralph Lauren', immagine: p68, prezzo: '59,99', categoria: 'Pants', immaginedietro: p68_dietro, taglie:['M', 'L', 'XL', 'XXL',], modello: 'Ralph Lauren Pants'},
    {brand: 'Ralph Lauren', immagine: p69, prezzo: '59,99', categoria: 'Pants', immaginedietro: p69_dietro, taglie:['M', 'L', 'XL', 'XXL',], modello: 'Ralph Lauren Pants'},
    {brand: 'Ralph Lauren', immagine: p70, prezzo: '59,99', categoria: 'Pants', immaginedietro: p70_dietro, taglie:['M', 'L', 'XL', 'XXL',], modello: 'Ralph Lauren Pants'},
    {brand: 'Nike', immagine: p71, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p71_dietro, taglie:['S', 'M', 'L', 'XL',], modello: 'Nike Jacket'},
    {brand: 'Nike', immagine: p72, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p72_dietro, taglie:['S', 'M', 'L', 'XL',], modello: 'Nike Jacket'},
    {brand: 'Nike', immagine: p73, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p73_dietro, taglie:['S', 'M', 'L', 'XL', ], modello: 'Nike Jacket'},
    {brand: 'Nike', immagine: p74, prezzo: '59,99', categoria: 'Giacca', immaginedietro: p74_dietro, taglie:['S', 'M', 'L', 'XL',], modello: 'Nike Jacket'},
    {brand: 'Moncler', immagine: p75, prezzo: '59,99', categoria: 'Maglione', immaginedietro: p75_dietro, taglie:['S', 'M', 'L', 'XL',], modello: 'Moncler Sweater'},
    {brand: 'Moncler', immagine: p76, prezzo: '59,99', categoria: 'Maglione', immaginedietro: p76_dietro, taglie:['S', 'M', 'L', 'XL',], modello: 'Moncler Sweater'},
    {brand: 'Moncler', immagine: p77, prezzo: '59,99', categoria: 'Maglione', immaginedietro: p77_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Moncler Sweater'},
    {brand: 'Gucci', immagine: p78, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p78_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Gucci Sweater'},
    {brand: 'Gucci', immagine: p79, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p79_dietro, taglie:['S', 'M', 'L', 'XL'], modello: 'Gucci Sweater'},
    {brand: 'Lacoste', immagine: p80, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p80_dietro, taglie:['M', 'L', 'XL'], modello: 'Lacoste Hoodie'},
    {brand: 'Prada', immagine: p81, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p81_dietro, taglie:['S','M', 'L', 'XL'], modello: 'Prada Sweater', trend:true},
    {brand: 'Prada', immagine: p82, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p82_dietro, taglie:['S','M', 'L', 'XL'], modello: 'Prada Sweater'},
    {brand: 'The North Face', immagine: p83, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p83_dietro, taglie:['S','M', 'L', 'XL'], modello: 'TNF Sweater'},
    {brand: 'The North Face', immagine: p84, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p84_dietro, taglie:['S','M', 'L', 'XL'], modello: 'TNF Sweater'},
    {brand: 'Gucci', immagine: p85, prezzo: '69,99', categoria: 'Maglione', immaginedietro: p85_dietro, taglie:['M', 'L', 'XL'], modello: 'Gucci Sweater', trend:true},
    {brand: 'Lacoste', immagine: p86, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p86_dietro, taglie:['M', 'L', 'XL'], modello: 'Lacoste Sweater'},
    {brand: 'Lacoste', immagine: p87, prezzo: '49,99', categoria: 'Maglione', immaginedietro: p87_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Lacoste Sweater'},
    {brand: 'Rhude', immagine: p88, prezzo: '54,99', categoria: 'Maglione', immaginedietro: p88_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Rhude Sweater'},
    {brand: 'Nike', immagine: p89, prezzo: '124,99', categoria: 'Giacca invernale', immaginedietro: p89_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Piumino Nike'},
    {brand: 'Nike', immagine: p90, prezzo: '124,99', categoria: 'Giacca invernale', immaginedietro: p90_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Piumino Nike'},
    {brand: 'Nike', immagine: p91, prezzo: '124,99', categoria: 'Giacca invernale', immaginedietro: p91_dietro, taglie:['M', 'L', 'XL', 'XXL'], modello: 'Piumino Nike'},
    {brand: 'Nike', immagine: p92, prezzo: '54,99', categoria: 'Pants', immaginedietro: p92_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Pants'},
    {brand: 'Nike', immagine: p93, prezzo: '54,99', categoria: 'Pants', immaginedietro: p93_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Pants'},
    {brand: 'Nike', immagine: p94, prezzo: '54,99', categoria: 'Pants', immaginedietro: p94_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Pants'},
    {brand: 'Nike', immagine: p95, prezzo: '54,99', categoria: 'Pants', immaginedietro: p95_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Pants'},
    {brand: 'Nike', immagine: p96, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p96_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Hoodie'},
    {brand: 'Nike', immagine: p97, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p97_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Hoodie'},
    {brand: 'Nike', immagine: p98, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p98_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Hoodie'},
    {brand: 'Nike', immagine: p99, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p99_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Nike Hoodie'},
    {brand: 'Fendi', immagine: p100, prezzo: '64,99', categoria: 'Maglione', immaginedietro: p100_dietro, taglie:['S','M', 'L', 'XL', 'XXL',], modello: 'Fendi Sweater'},
    {brand: 'AntiSocial', immagine: p101, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p101_dietro, taglie:['S','M', 'L', 'XL', 'XXL',], modello: 'Antisocial Hoodie', trend:true},
    {brand: 'AntiSocial', immagine: p102, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p102_dietro, taglie:['S','M', 'L', 'XL', 'XXL',], modello: 'Antisocial Hoodie'},
    {brand: 'Travis Scott', immagine: p103, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p103_dietro, taglie:['S','M', 'L', 'XL',], modello: 'Travis Scott Hoodie'},
    {brand: 'Nike', immagine: p104, prezzo: '109,99', categoria: 'Sneakers', immaginedietro: p104_dietro, taglie:['Da 39 a 47.5'], modello: 'Air Force 1 x Supreme', trend:true, modello: 'Air Force 1'},
    {brand: 'Nike', immagine: p105, prezzo: '109,99', categoria: 'Sneakers', immaginedietro: p105_dietro, taglie:['Da 39 a 47.5'], modello: 'Air Force 1 x Supreme', modello: 'Air Force 1'},
    {brand: 'Nike', immagine: p106, prezzo: '109,99', categoria: 'Sneakers', immaginedietro: p106_dietro, taglie:['Da 39 a 47.5'], modello: 'Air Force 1 x Supreme', modello: 'Air Force 1'},
    {brand: 'Adidas', immagine: p107, prezzo: '94,99', categoria: 'Giacca invernale', immaginedietro: p107_dietro, taglie:['S','M', 'L',], modello: 'Piumino Adidas'},
    {brand: 'Adidas', immagine: p108, prezzo: '94,99', categoria: 'Giacca invernale', immaginedietro: p108_dietro, taglie:['S','M', 'L',], modello: 'Piumino Adidas'},
    {brand: 'Adidas', immagine: p109, prezzo: '94,99', categoria: 'Giacca invernale', immaginedietro: p109_dietro, taglie:['S','M', 'L',], modello: 'Piumino Adidas'},
    {brand: 'Stussy', immagine: p110, prezzo: '129,99', categoria: 'Giacca invernale', immaginedietro: p110_dietro, taglie:['S','M', 'L','XL'], modello: 'Piumino Stussy'},
    {brand: 'Stussy', immagine: p111, prezzo: '129,99', categoria: 'Giacca invernale', immaginedietro: p111_dietro, taglie:['S','M', 'L','XL'], modello: 'Piumino Stussy'},
    {brand: 'Stussy', immagine: p112, prezzo: '129,99', categoria: 'Giacca invernale', immaginedietro: p112_dietro, taglie:['S','M', 'L','XL'], modello: 'Piumino Stussy'},
    {brand: 'Chrome', immagine: p113, prezzo: '54,99', categoria: 'Felpa', immaginedietro: p113_dietro, taglie:['M', 'L','XL', 'XXL'], modello: 'Chrome Hoodie'},
    {brand: 'Playboy', immagine: p114, prezzo: '59,99', categoria: 'Felpa', immaginedietro: p114_dietro, taglie:['S','M', 'L','XL','XXL'], modello: 'Playboy Hoodie'},
    {brand: 'Adidas', immagine: p115, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p115_dietro, taglie:['Dal 35 al 50'], modello: 'Campus 00s Black',trend: true},
    {brand: 'Adidas', immagine: p116, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p116_dietro, taglie:['Dal 35 al 50'], modello: 'Campus 00s Grey White', trend: true},
    {brand: 'Jordan', immagine: p117, prezzo: '109,99', categoria: 'Giacca invernale', immaginedietro: p117_dietro, taglie:['S','M','L','XL'], modello: 'Travis Scott x Air Jordan', trend: true},
    {brand: 'Nike', immagine: p118, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p118_dietro, taglie:['Dal 35 al 47.5'], modello: 'Nike Dunk Low Bordeaux', modello: 'Dunk Low'},
    {brand: 'Nike', immagine: p119, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p119_dietro, taglie:['Dal 35 al 47.5'], modello: 'Nike Dunk Low UNC', trend:true, modello: 'Dunk Low'},
    {brand: 'Nike', immagine: p120, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p120_dietro, taglie:['Dal 35 al 47.5'], modello: 'Nike Dunk Low Retro Grey', modello: 'Dunk Low'},
    {brand: 'Nike', immagine: p121, prezzo: '119,99', categoria: 'Sneakers', immaginedietro: p121_dietro, taglie:['Dal 35 al 47.5'], modello: 'Nike Dunk Low Panda', modello: 'Dunk Low'},


  ]);



  let codiceProdottoCounter = 91902;

  // Aggiungi la voce 'codice_prodotto' a ciascun prodotto mancante
  for (let i = 0; i < products.length; i++) {
    // Verifica se il prodotto corrente esiste e se non ha un codice_prodotto
    if (products[i] && !products[i].codice_prodotto) {
      products[i].codice_prodotto = codiceProdottoCounter.toString();
      codiceProdottoCounter++;
    }
  }

  let idCounter = 1; // Inizia da 1 o qualsiasi altro valore desiderato

  // Aggiungi l'id solo ai prodotti senza id
  for (let i = 0; i < products.length; i++) {
    if (!products[i].id) {
      products[i].id = 'p' + idCounter;
      idCounter++;
    }
  }



  return (
    <ProductContext.Provider value={{ products, setProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};