import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';


import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap"; // <-- JS File

import Home from './pages/Home.js';
import Abbigliamento from './pages/Abbigliamento';
import Sneakers from './pages/Sneakers';
import Dropshipping from './pages/Dropshipping.js';
import RootLayout from './pages/RootLayout.js';
import Chisiamo from './pages/Chisiamo.js';
import Comeacquistare from './pages/Comeacquistare.js';
import Prodotto from './pages/pagescomponents/Prodotto.js';
import Giacche from './pages/productcategory/Giacche.js';
import Error from './components/Error.js';
import Felpe from './pages/productcategory/Felpe.js'
import Maglioni from './pages/productcategory/Maglioni.js'
import Pants from './pages/productcategory/Pants.js'
import Nike from './pages/productcategory/Nike.js'
import Adidas from './pages/productcategory/Adidas.js'
import Jordan from './pages/productcategory/Jordan.js'
import Luxury_shoes from './pages/productcategory/Luxury_shoes.js'
import WelcomePopup from './components/WelcomePopup.js';

import Giacche_invernali from './pages/productcategory/Giacche_invernali.js'
import T_shirt from './pages/productcategory/T_shirt.js';
import Contatti from './pages/Contatti.js';
import Spedizione from './components/Spedizione.js';
import Esio_bucks from './components/Esiobucks.js';
import Politiche_di_reso from './components/Politichedireso.js';





function App() {
  return (
    <>
      <Router>
        <WelcomePopup />
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route index element={<Home />} />
            <Route path="Abbigliamento" element={<Abbigliamento />} />
            <Route path="Abbigliamento/:id" element={<Prodotto />} />
            <Route path="Sneakers" element={<Sneakers />} />
            <Route path="Dropshipping" element={<Dropshipping />} />
            <Route path="Spedizione" element={<Spedizione />} />
            <Route path="Esio_bucks" element={<Esio_bucks />} />
            <Route path="Politiche_di_reso" element={<Politiche_di_reso />} />
            <Route path="Chisiamo" element={<Chisiamo />} />
            <Route path="Abbigliamento/Giacche" element={<Giacche />} />
            <Route path="Abbigliamento/Felpe" element={<Felpe />} />
            <Route path="Abbigliamento/T_shirt" element={<T_shirt />} />
            <Route path="Sneakers/Nike" element={<Nike />} />
            <Route path="Sneakers/Jordan" element={<Jordan />} />
            <Route path="Sneakers/Adidas" element={<Adidas />} />
            <Route path="Sneakers/Luxury_shoes" element={<Luxury_shoes />} />
            <Route path="Abbigliamento/Maglioni" element={<Maglioni />} />
            <Route path="Abbigliamento/Pants" element={<Pants />} />
            <Route path="Contatti" element={<Contatti />} />
            <Route path="Abbigliamento/Giacche_invernali" element={<Giacche_invernali />} />
            <Route path="Comeacquistare" element={<Comeacquistare />} />
            <Route path="Error" element={<Error />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
