import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './RootLayout.css'; // Importa il tuo file CSS

function RootLayout (){
    return (
        <div className="rootLayout">
            <Navbar/>
            <div className="content">
                <Outlet/>
            </div>
            <Footer/>
        </div> 
    )
}

export default RootLayout;
