import campus from  './immagini/campus.png';
import './Evidence.css';
import gucci from './immagini/gucci.png';
import campus_evidence from './immagini/campus_Evidence.jpeg';
import { Link } from 'react-router-dom';

function Evidence () {
    return (
                <>
        <div className='responsive_campus m-3 m-lg-0'>
            <h5 className='ms-2 mb-1 mt-5 ms-lg-5 d-lg-none '>in evidenza</h5>
                <div className='d-sm-flex flex-nowrap justify-content-evenly align-items-center pt-lg-4'>
                    <img src={campus} className=' mt-3 mt-lg-0 mb-lg-0 mb-3 mt-sm-5 d-lg-none responsive_campus_img  rounded border border-dark border-2' />
                   
                    <div className='flex-column column_none '>
                    <p className='text-center'>ADIDAS CAMPUS 00s</p>
                    <img src={campus_evidence} className='rounded m-5 mt-3' />
                    </div>

                   <div className='flex-column column_none text-start m-5 mt-0' style={{width: '50%'}}>
                    <h5 className='text-center mb-5' style={{color: 'orange'}}>IT'S ANOTHER WAY</h5>
                    <span className=''>Rinnova il tuo stile con le nuove adidas Campus 00s! Con un design originale e colorato ispirato al modello classico, 
                    queste scarpe offrono comfort e qualità grazie alla tomaia in pelle scamosciata e alla suola in gomma. Disponibili in vari colori per adattarsi a ogni outfit e umore, le adidas Campus 00s ti faranno sentire unico.
                     Approfitta delle nostre offerte speciali e ordina ora per trasformare il tuo look con le scarpe che fanno la differenza!
                        </span>
                        <Link to='Sneakers/Adidas' style={{textDecoration: 'none'}}>
                        <button className='btn d-block mx-auto btn-light mt-5'>SCOPRI ORA</button>
                        </Link>
                    </div>
                    <img src={gucci} className=' mt-3 mb-3 mt-sm-5 responsive_campus_img  rounded d-lg-none' />

                </div>
        </div>
                </>
    )
}

export default Evidence;