import {useSpring, animated} from 'react-spring';
import React, { useState, useEffect} from 'react';import './Category.css';
import Filtermenu from '../pagescomponents/Filtermenu';
import Productcomponents from '../../components/Productcomponents';
import { Link } from 'react-router-dom';
import { useProductContext } from '../ProductContext';
import Productmenu from '../pagescomponents/Productmenu';


function Felpe() {
  const { products, setProducts } = useProductContext();
  const [selectedBrand, setSelectedBrand] = useState('');

  const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
  useEffect(() => {
          window.scrollTo(0, 0);
        }, []);

  const handleBrandChange = (brand) => {
    setSelectedBrand(brand);
    // Aggiorna il contesto globale solo se lo desideri
    // setProducts([...]); // Aggiorna con la tua logica di aggiornamento
  };

  const filteredProducts = selectedBrand
    ? products.filter((prod) => prod.brand === selectedBrand && prod.categoria === 'Felpa')
    : products.filter((prod) => prod.categoria === 'Felpa');

  return (
    <animated.div style={props}>
    <div className='title mt-4'>
      <h2 className='text-center'>Felpe</h2>
      <div className='responsive_page'>

      <div className='d-flex algin-items-center justify-content-between' style={{backgroundColor: 'black'}}>
      <Productmenu menutitle='CERCA ALTRO'/>
      <Filtermenu onBrandChange={handleBrandChange} />
       </div>

      <div className="p-2 pt-0 d-flex flex-wrap align-items-center justify-content-evenly mt-3">
        {filteredProducts.map((prod) => (
          <Link to={`/Abbigliamento/${prod.id}`}  style={{ textDecoration: 'none', color: 'black'}} key={prod.id}>
            <Productcomponents immagine={prod.immagine} brand={prod.brand} categoria={prod.categoria} prezzo={prod.prezzo} />
          </Link>
        ))}
      </div>
    </div>
    </div>
    </animated.div>
  );
}

export default Felpe;