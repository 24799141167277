import './FollowonIG.css'
import insta_logo from '../pagescomponents/images/Instagram-Icon.png'
function FollowonIG (){
    return(
        <div className='mb-2 mt-1'>
            <p className="p_ig text-center">FOLLOW US ON IG</p>
            <img src={insta_logo} className='mx-auto d-block mb-3' width={50}/>
            <div className='d-flex justify-content-center'>
            <p className='text_center p_ig2 ps-2 pe-2'>@sh0pp3sio</p>
            </div>
        </div>
    )
}

export default FollowonIG;