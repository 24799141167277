import './Sliderproduct.css'


function Sliderproduct_components(props){
return(
    <li style={{ listStyle:'none' }} className='background_slider border border-1 border-dark me-4' >
    <img src={props.immagine} className="p-3 pb-0 pt-0 imagewidth" alt="okay"/>
    <p
      className="text-start mt-3 mb-1"
      style={{
        fontSize: 19,
      
        fontFamily: '"Bebas Neue", sans-serif',
        fontWeight: '600'
      }}
    >
     {props.modello}
    </p>
    <p className='mt-0 mb-0' style={{fontFamily:'"Raleway", sans-serif', color: 'black', fontWeight:'500', fontSize:'16px', textDecoration:'underline'}}>{props.brand}</p>
    
    <div className='d-flex align-items-center'>
    <p className='m-0 mt-2 text-decoration-none' style={{fontFamily:'"Anton", sans-serif', color: 'black', fontWeight:'100', fontSize:'17px', textDecoration:'underline'}}>{props.prezzo} </p>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-currency-euro pt-1" viewBox="0 0 16 16">
      <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"/>
      </svg>
    </div>
    
  </li>

)
}
export default Sliderproduct_components;