import './Abbigliamento.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap"; // <-- JS File

import { useProductContext } from './ProductContext';

import Doublewrite from '../components/Doublewrite';
import Productmenu from './pagescomponents/Productmenu';
import Productcomponents from '../components/Productcomponents';
import Thenorthface from './pagescomponents/Thenorthface';
import Antisocialclub from './pagescomponents/Antisocialclub';
import {useSpring, animated} from 'react-spring';
import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import FollowonIG from "../pages/pagescomponents/FollowonIG";
import {Link} from "react-router-dom";
import New_product from '../components/New_product';


function Abbigliamento (){



  const [show, setShow] =useState(false);
  const handleShow=() => {setShow(true)}
  const handleClose=() => {setShow(false)}


  const [isAbbigliamentoMenuOpen, setAbbigliamentoMenuOpen] = useState(false);
  const [isSneakersMenuOpen, setSneakersMenuOpen] = useState(false);

  const toggleAbbigliamentoMenu = () => {
    setAbbigliamentoMenuOpen(!isAbbigliamentoMenuOpen);
    setSneakersMenuOpen(false);
  };

  const toggleSneakersMenu = () => {
    setSneakersMenuOpen(!isSneakersMenuOpen);
    setAbbigliamentoMenuOpen(false);
  };



  
  const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
    const { products } = useProductContext();


    const scritte ={firstwrite: 'OUR CATALOG', secondwrite: 'OUR CATALOG'}
 
    
    const northFaceProducts = products.filter(product => product.categoria === 'Giacca invernale');

    // Seleziona solo i primi 6 prodotti
    const selectedProducts = northFaceProducts;



          return (
            
        <>
<animated.div>
        <Doublewrite firstwrite={scritte.firstwrite} secondwrite={scritte.secondwrite}/>
        <div className='abbigliamento_responsive'>
        <New_product title="Affronta l'inverno con stile" products={selectedProducts}/> {/* Passa i prodotti selezionati come props */}
        </div>

  
<Thenorthface/>





<div className='mt-5 ms-lg-5'>
<h3 className='lastproducts text-start mb-3 mb-lg-4 '>ULTIMI PRODOTTI</h3>
<Productmenu/>

<div className="d-flex flex-wrap align-items-center justify-content-evenly mt-3 ">
{[...products].reverse().slice(0, 40).map((prod) => (
  <Link to={`/Abbigliamento/${prod.id}`}  style={{ textDecoration: 'none', color: 'black'}} key={prod.id}>
      <Productcomponents immagine={prod.immagine} brand={prod.brand} categoria={prod.categoria} prezzo={prod.prezzo}/>
  </Link>
))}

      </div>

          
          <div className='d-flex justify-content-center align-items-center mt-5 mb-4'>
          <button className='btn btn-warning' style={{fontFamily: "'Anton', 'sans-serif'", fontSize:'20px'}} onClick={handleShow}>SCOPRI ALTRO</button>
          </div>

</div>













</animated.div>
</>

    )
}

export default Abbigliamento;

