import Doublewrite from './Doublewrite';
import './Product.css'
import bucks from './immagini/Progetto senza titolo (1).png'
import spedizione from '../pages/pagescomponents/images/shipstreetwear.png'
import reso from '../pages/pagescomponents/images/reso.png'
import {useSpring, animated} from 'react-spring'
import louisvuitton_bag from './immagini/louisvuitton_bag.png'
import React, { useState, useEffect} from 'react';


function Esio_bucks (){


const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
useEffect(() => {
window.scrollTo(0, 0);
}, []);
  


    return(
        <>

    <animated.div style={props}>

    <div className='spedizione'>
        <Doublewrite firstwrite='ESIO BUCKS' secondwrite='ESIO BUCKS'/>

        <p style={{fontFamily: "'Nosifer', 'sans-serif'", fontSize: '18px', fontWeight: '900'}} className='text-center mt-3'>Cosa sono?</p>

        
            <div className='ms-3 mt-5 me-2'>
            <span className='' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800', color: 'white', backgroundColor: 'black'}}>
                Come funziona il sistema di accumulazione punti "ESIO BUCKS"?</span>
                <div className='d-flex justify-content-center mb-3 mt-4'>
            <img src={bucks} width={150} />
        </div>
                <span  style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>Il nostro sistema di ESIO BUCKS ti permettere di accumulare punti ad ogni acquisto che fai, pari al 10% del prezzo del tuo acquisto. Se ad esempio acquisti un articolo a 120,00 $ riceverai un totale di punti pari a 12, ovvero il suo 50%.
           <br></br><span style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}} className='border border-2 border-dark'>Una volta accumulati i punti, potrai decidere di utilizzarli per ottenere un buono da convertire nel prossimo acquisto, oppure decidere di continuare ad accumularli. I punti dovranno essere utilizzati entro 4 mesi, altrimenti scadranno!</span>
            </span>
            </div>

            <div className='ms-3 mt-5 me-2'>
            <span className='' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800', color: 'white', backgroundColor: 'black'}}>
                Riscatta premi e prodotti accumulando ESIO BUCKS</span><br/>
                <span  style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>Un altro modo che hai di spendere gli "ESIO BUCKS" è quello di riscattare premi e prodotti posti da noi in palio. Ogni premio richiede un valore di punti diverso, accumulane il ncessario e ottieni il tuo premio!
           <br></br><span style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}} className='border border-2 border-dark'>Rimani aggiornato sulla nostra pagina Instagram per non perderti i premi riscattabili accumulando i nostri punti.</span>
            </span>
            </div>

            
        
</div>
    </animated.div>

        </>
    )
}


export default Esio_bucks;