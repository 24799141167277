import Doublewrite from "../components/Doublewrite";
import'./Chisiamo.css';
import './Dropshipping.css';
import Paypal_logo from './pagescomponents/images/paypal_head.png';
import bonifico_bancario from './pagescomponents/images/Logo-bonifico-bancario.png';
import prepaid_logo from './pagescomponents/images/carta_prepagata_img.png';
import React, { useState, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import payment from '../components/immagini/paymnt.png';
import payment_2 from '../components/immagini/payment_2.png';


function Comeacquistare (){

const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
<animated.div style={props}>

        <Doublewrite firstwrite='COME ACQUISTARE' secondwrite='COME ACQUISTARE'/>
     
        <div className=" mt-5 mb-4  responsive_come_pagare">
    
        <div className="text_dropshipping ">
            <h4 className="ps-2 pe-2 mb-2 text-decoration-underline ">COME POSSO PAGARE SU SH0PP3SIO?</h4>
            <p  className="ps-2 pe-2">Purtroppo attualmente ancora non è possibile pagare gli articoli che vuoi comprare direttamente sul nostro sito. 
            Il nostro staff è al lavoro per implementare un processo di pagamento che ti permetterà di acquistare i nostri capi direttamente dal sito.</p>
            <img src={payment_2} className="d-block mx-auto" width={200} />
            <p className="ps-2 pe-2 " style={{fontWeight: '900', textDecoration: 'underline'}}> Per Saldare il tuo ordine, al momento, fin quando non potrai farlo direttamente sul nostro sito, dovrai completare il processo di pagamento direttamente con il nostro staff su Instagram. Scopri di più qua sotto!</p>

            <h4 className="ps-2 pe-2 mb-2 mt-5">INSTAGRAM PROCESS</h4>
            <p style={{fontWeight: '900'}} className="ps-2 pe-2 mb-4 text-decoration-underline"> Questo avviene seguendo tre step:</p>
            <p style={{fontFamily: "'Raleway', 'sans-serif'", fontWeight: '900', fontSize:'15px', color: 'red'}} className="border text-decoration-underline border-dark border-2 ps-2 pe-2 mb-1 ms-3 mt-0">1) Acquisisci il "Codice prodotto" di ogni singolo articolo che intendi acquistare".</p>
            <p className="ps-2 pe-2 mb-4 ms-4 mt-2">Il codice prodotto lo trovi in basso a sinistra nella pagina del prodotto che vuoi comprare. Aquisiscili e comunicaglieli al nostro staff su Instagram, tramite direct, indicando anche la taglia scelta da te.</p>
            <p style={{fontFamily: "'Raleway', 'sans-serif'", fontWeight: '800', fontSize:'15px', color: 'red'}} className="border text-decoration-underline border-dark border-2 ps-2 pe-2 mb-1 ms-3 mt-0">2) Il nostro Staff ti risponderà nel giro di un paio di ore, prendendo in elaborazione la tua richiesta. </p>
            <p style={{ fontWeight: '800'}} className="ps-2 pe-2 mb-4 ms-4 mt-2">Una volta presa in esame la tua richiesta, il nostro staff in pochi minuti ti inolterà uno scontrino digitale dove troverai tutte le tue specifiche dell'ordine. Quello che dovrai fare è riesaminare tutti i dettagli, e in seguito confermare la richiesta d'ordine.</p>
            <p style={{fontFamily: "'Raleway', 'sans-serif'", fontWeight: '800', fontSize:'15px', color: 'red'}} className="border  text-decoration-underline border-dark border-2 ps-2 pe-2 mb-1 ms-3 mt-0">3) Ora quello che devi fare è pagare l'ordine entro 72 ore. </p>
            <p style={{fontWeight: '800'}} className="ps-2 pe-2 mb-2 ms-4 mt-2">Arrivati a questo punto, l'ultimo passaggio da effettuare prima di vederti arrivare il pacco a casa, è pagare l'ordine. Avrai 72h a disposizione dalla conferma dell'ordine per saldare il pagamento. Hai a disposizione svariate opzioni per pagare.</p>
            </div>

            <div className="text_dropshipping">

            <h4 className="mb-4 mb-md-5 text-center mt-5 h4_drop_responsive" style={{ fontFamily: "'Nosifer','sans-serif'", fontWeight: '900'}} >MODALITA' DI PAGAMENTO</h4>
            <img src={payment} className="d-block mx-auto" width={120} />
            
            <p className="ps-2 pe-2 mt-4 mb-4 text-decoration-underline" style={{fontWeight: '900'}}> Come dicevano prima, hai a disposizione più di una scelta per paagre su Sh0PP3sio. </p>
            <div className="d-flex justify-content-start align-items-center mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="Black" class="bi bi-1-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z"/>
            </svg>
            <h4 className="  bg-warning ps-2 pe-2 mb-1 pt-1 pb-1 ms-4" style={{fontSize: '23px'}}>PAYPAL</h4>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                <p className="ps-2 mb-0"> Con Paypal è possibile acquistare i nostri articoli
                 in maniera sicura con la sicurezza di poter essere rimobrsati in qualsiasi momento!</p>
                 <img src={Paypal_logo} className="responsive_img_1" />
            </div>
                 <p className="ps-2 mb-0">Ti basta possedere un conto Paypal! (puoi anche crearlo al momento, ci impieghi un paio di minuti). Con Paypal potrai pagarci tramite "Protezione acquisiti e Protezione dalle frodi", che ti permette di essere assicurato in ogni istante, fin quando non ricevi il tuo prodotto direttamente a casa.</p>
                <div className="d-flex ailgn-items-start justify-content-center mt-4 mb-5">
                <button className="btn btn-info "><a target="_blank"  href="https://www.paypal.com/it/webapps/mpp/paypal-safety-and-security" style={{fontFamily: "'Raleway', 'sans-serif", color: 'black', fontWeight: '900'}}>SCOPRI DI PIU' CLICCANDO QUI</a></button>
                </div>

            <div className="d-flex justify-content-start align-items-center mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="Black" class="bi bi-2-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z"/>
            </svg>
                    <h4 className="  bg-warning ps-2 pe-2 mb-1 pt-1 pb-1 ms-4 mt-0" style={{fontSize: '23px'}}>RICARICA PREPAGATA</h4>
            </div>
            <p  className="ps-2 mb-0 mt-4"> Puoi decidere di pagarci anche ricaricando una delle nostre carte prepagate</p>

            <div className="d-flex align-items-center justify-content-center mt-2">

                <img src={prepaid_logo} className="responsive_img_2" />
                <p  className="ps-2 mb-0 mt-2">Effettuando una ricarica in un punto (es.tabacchino, poste ecc...) o tramite l'applicazione della vostra filiale potrai effettuare il pagamento a uno dei nostri conti di riferimento.</p>

            </div>

            <div className="d-flex justify-content-start align-items-center mt-5 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" class="bi bi-3-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z"/>
            </svg>
            <h4 className="  bg-warning ps-2 pe-2 mb-1 pt-1 pb-1 ms-4 mt-0" style={{fontSize: '23px'}}>BONIFICO BANCARIO</h4>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-4">
            <p  className="ps-2 me-3 mb-0 mt-2">Puoi anche scegliere di effettuare un Bonifico Bancario verso i nostri conti. Ti forniremo l'IBAN e una volta che avremo ricevuto il pagamento avvieremo l'orddine.</p>
                <img src={bonifico_bancario} className="me-3 responsive_img_3"/>
            </div>
            </div>
            <div className="d-flex justify-content-end mt-5"> 
        <span style={{fontFamily: "'Nosifer', 'sans-serif'", backgroundColor: 'black', color: 'white'}} className="text-end  ps-2 pe-2 me-3 mb-3">ILTEAM SH0PP3SIO</span>
        </div> 
            </div>



</animated.div>
</>

    

    )
}

export default Comeacquistare;