import emailjs from '@emailjs/browser';
import './ContactForm.css';

const ContactForm = () => {
  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Ottieni i valori dei campi email e messaggio dal modulo
    const email = event.target.email_id.value;
    const message = event.target.message.value;
    const name = event.target.from_name.value;


    // Implementa la logica di invio usando emailjs
    emailjs.send(
      'service_shoppesio_id',
      'sh0pp3sio_id',
      { email_id: email, from_name: name, message: message },
      '06OXR2YKbtzUWgsIc'
    )
    .then((response) => {
      console.log('Messaggio inviato con successo!', response);
      // Aggiungi qui la logica per gestire il successo dell'invio
    })
    .catch((error) => {
      console.error('Errore nell\'invio del messaggio:', error);
      // Aggiungi qui la logica per gestire l'errore nell'invio
    });

    // Resetta il modulo se necessario
    event.target.reset();
  };

  return (
    
    <form className="contact-form p-4" style={{backgroundColor: 'black'}} onSubmit={handleFormSubmit}>
      <label>Email:</label>
      <input
        type="email"
        name="email_id"
        placeholder="Inserisci la tua email"
        required
      />
       <label>Nome:</label>
      <input
        type="name"
        name="from_name"
        placeholder="Inserisci il tuo nome"
        required
      />

      <label>Messaggio:</label>
      <textarea
        name="message"
        placeholder="Inserisci il tuo messaggio"
        required
      ></textarea>

      <button type="submit">Invia</button>
    </form>
    
  );
};

export default ContactForm;