import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Doublewrite from '../../components/Doublewrite';
import { useProductContext } from '../ProductContext';
import {useSpring, animated} from 'react-spring';





import './Prodotto.css'
import Sliderproduct from '../../components/Sliderproduct';


function Prodotto() {
  const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
  const { id } = useParams();
  const { products } = useProductContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const trendingProducts = products.filter(product => product.trend === true);

  // Seleziona solo i primi 6 prodotti
  const selectedProducts = trendingProducts;

  // Trova il prodotto corrispondente in base all'ID
  const product = products.find((prod) => prod.id === id);

 
  // Estrai il titolo e l'immagine dal prodotto
  const { immagine, brand, immaginedietro, prezzo, categoria, descrizione, codice_prodotto, taglie, modello} = product;


  // Definisci lo stato per tenere traccia dell'immagine corrente
  const [immagineCorrente, setImmagineCorrente] = useState(immagine);

  useEffect(() => {
    setImmagineCorrente(immagine);
  }, [id, immagine]);
  

  // Funzione per cambiare l'immagine tra fronte e dietro
  const cambiaImmagine = () => {
    if (immagineCorrente === immagine) {
      setImmagineCorrente(immaginedietro);
    } else {
      setImmagineCorrente(immagine);
    }
  };



  return (
    <>
     <animated.div style={props}>
     <div className='container_prodotto'> 
      <Doublewrite firstwrite={categoria} secondwrite={categoria} />
      <h4 className='ps-4 pe-4 h4modello mt-4'>{modello}</h4>
      
      <div className=' mt-3 mb-3 backprodotto mt-lg-5 mb-md-5'>
        <img
          src={immagineCorrente}
          alt={brand}
          className=' d-block  img_yes '
        />
        <img
          src={immaginedietro}
          width={270}
          alt={brand}
          className=' img_none '
        />
      </div>

      <span className='ms-5 spandescr'>{brand}</span>
      <p className='ms-5 spandescr_2' style={{fontSize: '20px'}}>Codice prodotto = {codice_prodotto}</p>
      <div className='ms-5 mt-2  d-flex '>
      <img src={immagine} onClick={cambiaImmagine} width={100} alt={brand} className='border border-2 border-dark me-3'/>
      <img src={immaginedietro} onClick={cambiaImmagine} width={100} alt={brand} className='border border-2 border-dark'/>
      </div>
      

      <div className='ms-5 mt-4 taglia-parents'>
  <h4>Taglie disponibili:</h4>
  <div className="taglie-container">
    {taglie.map((taglia, index) => (
      <div key={index} className="taglia-box border border-dark border-1">
        {taglia}
      </div>
    ))}
  </div>
</div>

<div className='me-3 p_ship mt-4'>
<div className='d-flex justify-content-end align-items-center'>
  <p className='mb-0'>Spedizione gratuita in tutta Italia, in 14 giorni!</p>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" ms-2 bi bi-truck" viewBox="0 0 16 16">
        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
      </svg>
</div>
</div>

    <div className='align-items-center float-end mt-4 me-4 divprezzo'>
      <div className='d-flex'>
        
    <p className='ps-1 pe-1 pdisp'>DISPONIBILE</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="black" class="bi bi-check" viewBox="0 0 16 16">
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
      </svg>
      </div>

      <div className='d-flex align-items-center  justify-content-end'>
      <span className='ps-1 pe-1'>{prezzo}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
        <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"/>
        </svg>
        </div>
    </div>
  

    <hr style={{height: '7px', backgroundColor: 'black', opacity:'1', width: '100%',marginTop:'150px'}} className='mb-0'></hr>
        <div  className='ms-2'>
    <Sliderproduct products={selectedProducts}  title="I PIU' RICHIESTI" />
       </div>
       </div>
       </animated.div>
    </>
  );
}

export default Prodotto;