import './Blob.css'

function Blob(){
    return ( 
        

<>
  <div className="containerblob containerliquid">
    <div className="blobs">
      <div className="liquid" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
      <div className="blob" />
    </div>
    <h1 className="text h1liquid" style={{fontFamily: "'Nosifer','sans-serif'"}}>
      WELCOME
      <br></br>
      TO 
      <br></br>
      SH0PP3SIO
    </h1>
  </div>
  <div className="" />
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height={0}>
    <defs>
      <filter id="goog">
        <feGaussianBlur in="SourceGraphic" stdDeviation={10} result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result="goo"
        />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation={10} result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result="goo"
        />
        <feBlend in="SourceGraphic" in2="goo" />
      </filter>
    </defs>
  </svg>
</>

    )
}
export default Blob;