import Doublewrite from './Doublewrite';
import './Product.css'
import bucks from './immagini/Progetto senza titolo (1).png'
import spedizione from '../pages/pagescomponents/images/shipstreetwear.png'
import reso from '../pages/pagescomponents/images/reso.png'
import {useSpring, animated} from 'react-spring'
import Speed_Arrow from './immagini/speed_Arrow.png'
import React, { useState, useEffect} from 'react';


function Spedizione (){


const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
useEffect(() => {
window.scrollTo(0, 0);
}, []);
  


    return(
        <>

    <animated.div style={props}>
         <div className='spedizione'>
        <Doublewrite firstwrite='SPEDIZIONE' secondwrite='SPEDIZIONE'/>
            <p style={{fontFamily: "'Nosifer', 'sans-serif'", fontSize: '20px',fontWeight: '900'}} className='text-center'>Le nostre politiche</p>

            <div className='ms-3 mt-4 me-2 mb-5'>
            <span className='' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'100', color: 'black'}}>
              Prima di continuare a leggere, ricorda che i tempi di spedizione, si calcolano non dal momento che hai affettuato l'acquisto, bensì dal momento che gli articoli spediti dal nostro fornitore sono arrivati ad uno dei nostri magazzini di riferimento.  
            </span>
            <br></br><span style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}} className='border border-2 border-dark'>Questa operazione richiede in media 3-4 giorni.</span>
            </div>



            <div className='ms-3 me-3'>
            <span className='' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800', color: 'white', backgroundColor: 'black'}}>Come avviene la spedizione, in quanto tempo? E' gratuita?</span>
           <div className='d-flex justify-content-center'>
           <img src={spedizione} width={200} alt='spedizione' />
           </div>
           <span  style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>
            La spedizione sul nostro sito è semplice e conveniente. Offriamo spedizione gratuita in tutta Italia per ordini superiori a 49,99 euro. Altrimenti, il costo della spedizione è di soli 5,99 euro.
<br></br>Tieni presente che i tempi di spedizione potrebbero richiedere da 10 a 14 giorni, il che è leggermente più lungo rispetto ad alcuni negozi online. Questo perché utilizziamo il sistema di dropshipping, il che significa che i nostri fornitori terzi spediscono direttamente i prodotti ai clienti.
<br></br><span style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}} className='border border-2 border-dark'>Nonostante il tempo aggiuntivo, garantiamo che ne varrà la pena quando riceverai il tuo streetwear di alta qualità direttamente a casa tua.</span>
            </span>
            </div>

            <div className='ms-3 mt-5 me-2 mb-4'>
            <span className='' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800', color: 'white', backgroundColor: 'black'}}>
               C'è un modo per ottenere i miei articoli in meno tempo?</span>
            <div className='d-flex justify-content-center mt-2 mb-2'>
            <img src={Speed_Arrow} width={150} alt='reso' />
        </div>
        <span  style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>Se desideri ottenere i tuoi articoli in un tempo ancora più breve, ti daremo la possibilità nel momento dell'acquisto, di selezionare una modalità di spedizione rapida (con DHL) al solo prezzo di 14,99 se il valore dell'ordine è inferiore ai 99,99 euro, mentre al prezzo di 9,99 euro se il prezzo complessivo del tuo ordine è superiore ai 99,99 euro. Infine se il tuo acquisto sarà di un valore pari a 149,99 euro o superiore, ti regaleremo questa possibiità di spedizione rapida.
           <br></br><span style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}} className='border border-2 border-dark'>Selezionano questa modalità, il prodotto ti arriverà a casa entro una settimana. </span>
            </span>
            </div>
        </div>
    </animated.div>

        </>
    )
}


export default Spedizione;