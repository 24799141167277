import tnf2 from './images/thenorthface3.png';
import Productcomponents from '../../components/Productcomponents';
import tnfwallpaper from './images/XslRCp.jpg';
import '../Abbigliamento.css'
import { useProductContext } from '../ProductContext'
import prodotto2 from '../../components/immagini/Immagine WhatsApp 2024-01-27 ore 21.11.12_10b2c395.jpg'
import prodotto2wide from '../../components/immagini/scontitino3.png';

function Thenorthface (){

const { products } = useProductContext();



    return (

<div>
    <img src={prodotto2} className='img-fluid d-sm-none'/>
    <img src={prodotto2wide} className='img-fluid d-none d-md-block'/>

</div>
 )
}

export default Thenorthface;