import './Chisiamo.css';
import Doublewrite from '../components/Doublewrite';
import Logo from './pagescomponents/images/logo.png';
import chisiamowp from './pagescomponents/images/wallpaperchisiamo.jpg'
import antisocial from './pagescomponents/images/Anti Social Social Club Pink Porsche Wallpaper.jpg';
import download4 from './pagescomponents/images/download (4).jpg'
import React, { useState, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';


function Chisiamo () {

    const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
     <animated.div style={props}>

        <Doublewrite firstwrite='CHI SIAMO' secondwrite='CHI SIAMO'/>

        <div className='p-4 d-inline-flex flex-wrap justify-content-evenly align-items-center' style={{backgroundColor:'black'}}>
           
            <h3 className='h3graphics mb-4 mt-3 m-auto'>Scopri di più su di noi</h3>

            <div className='flex-row'>
            <img src={chisiamowp} className='mx-auto rounded d-block' width={300} />
            <h3 className='h4graphics mb-2 mt-4 text-decoration-underline'>QUANDO NASCIAMO?</h3>
            <p className='spangraphics ps-4 pe-1 pt-3 '>
            Nel 2019, SH0PP3SIO è nato dalla passione condivisa di due amici per lo streetwear. Ma la nostra storia ha una svolta unica: si è sviluppata durante il periodo senza precedenti del COVID-19, in cui i lockdown hanno spinto molti a cercare nuovi modi di esprimersi e connettersi attraverso lo stile. Da un semplice interesse è cresciuto un impegno profondo per trasformare questa passione in un lavoro, con l'obiettivo di diffondere la cultura dello streetwear su una scala più ampia.
            </p>  
            </div>

            <div className='flex-row'>
            <img src={antisocial} className='img-fluid'/>    
            <h3 className='h4graphics mb-2 mt-4  text-decoration-underline'>CHI SIAMO</h3>     
            <p className='spangraphics ps-4 pe-1 pt-3 '>Siamo una community di creativi, amanti dell'arte urbana e della moda alternativa. Ogni capo presente nel nostro catalogo rappresenta il nostro impegno a offrire stile, qualità e originalità. 
                Ma siamo più di un negozio di abbigliamento; siamo una visione condivisa di espressione e libertà attraverso il tuo look.
            </p>
            </div>

            <div className='flex-row'>
                <img src={download4} className='img-fluid mx-auto rounded d-block'/> 
                <h3 className='h4graphics mb-2 mt-4 text-decoration-underline'>PERCHE' NOI?</h3>  
            <p className='spangraphics ps-4 pe-1 pt-3'>Quando scegli di fare shopping da SH0PP3SIO, stai abbracciando una parte della cultura streetwear, e noi siamo qui per guidarti in questo viaggio. 
                Unisciti a noi mentre cerchiamo di portare il nostro stile di vita unico a un pubblico più ampio. Benvenuto nel nostro mondo, dove lo streetwear è più di un abbigliamento: è un'identità, un movimento, una forma d'arte.
                </p>
            </div>
            
            <img src={Logo} className='mx-auto d-block' width={125} />
        </div>
        </animated.div>
        </>
    )
}

export default Chisiamo;