import './Navbar.css'
import logo from './immagini/logo.png'
import carrello from './immagini/carrellobianco.png'
import utente from './immagini/utentebianco.png' 
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import openImage from './immagini/justify.png'
import closeImage from './immagini/x.png'

import { ModalBody, ModalFooter } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import insta_logo from '../pages/pagescomponents/images/Instagram-Icon.png';
import { slide as Menu } from 'react-burger-menu';
import anotherImage from './immagini/x.png'

import { ChevronRight, ArrowLeftCircleFill } from 'react-bootstrap-icons'; // Importa le icone da react-bootstrap-icons

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showAbbigliamentoSubMenu, setShowAbbigliamentoSubMenu] = useState(false);
  const [showSneakersSubMenu, setShowSneakersSubMenu] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
    setShowAbbigliamentoSubMenu(false); // Chiudi i sottomenu quando il menu principale viene chiuso
    setShowSneakersSubMenu(false); // Chiudi i sottomenu quando il menu principale viene chiuso
  };

  const toggleAbbigliamentoSubMenu = () => {
    setShowAbbigliamentoSubMenu(!showAbbigliamentoSubMenu);
    setShowSneakersSubMenu(false); // Chiudi l'altro sottomenu se aperto
  };

  const toggleSneakersSubMenu = () => {
    setShowSneakersSubMenu(!showSneakersSubMenu);
    setShowAbbigliamentoSubMenu(false); // Chiudi l'altro sottomenu se aperto
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      {/*navbar*/}
      <div id="main" className='fixed-top'>
        <div style={{ backgroundColor: "rgb(234, 158, 27)" }}>
          <p
            className="text-center pb-1"
            style={{
              fontFamily: '"Raleway", sans-serif',
              marginBottom: 0,
              color: "black",
              fontWeight: '900',
              fontSize: 'small',
            }}
          >
            <strong>
              <u>
                {" "}
                - STREETWEAR DROPSHIPPING STORE -<br></br>
                - SPEDIZIONE GRATUITA -
              </u>
            </strong>
          </p>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light  fixednavbar d-flex justify-content-between pt-lg-3 pb-lg-3" style={{ backgroundColor: 'black' }}>
          <img alt='okay' src={isOpen ? closeImage : openImage} onClick={toggleNav} width="40" height="40" className='ms-3 d-lg-none' />

          <div className={`sidebar ${isOpen ? 'open' : ''} pb-5`}>
            <div className='sidebarok p' style={{ width: '100%', marginTop: '20px' }}>
              {!showAbbigliamentoSubMenu && !showSneakersSubMenu && (
                <>
                  <Link to='/' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>
                      Home
                    </p>
                  </Link>

                  <div onClick={toggleAbbigliamentoSubMenu}>
                    <p>
                      Abbigliamento <ChevronRight />
                    </p>
                  </div>

                  <div onClick={toggleSneakersSubMenu}>
                    <p>
                      Sneakers <ChevronRight />
                    </p>
                  </div>

                  <Link to='/Dropshipping' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>
                      Dropshipping
                    </p>
                  </Link>

                  <Link to='/Chisiamo' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>
                      Chi siamo
                    </p>
                  </Link>

                  <Link to='/Contatti' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>
                      Contatti
                    </p>
                  </Link>

                  <Link to='/Comeacquistare' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>
                      Come acquistare
                    </p>
                  </Link>
                </>
              )}

              {showAbbigliamentoSubMenu && (
                <div className='pl-3'>
                  <p onClick={toggleAbbigliamentoSubMenu} style={{ textAlign: 'center' }}><ArrowLeftCircleFill /> <strong>Abbigliamento</strong></p>
                  
                  <Link to='/Abbigliamento' style={{ textDecoration: 'none',}} onClick={toggleNav}>
                    <p style={{fontWeight: '900', textDecoration: 'underline', color: 'red' }}>Scopri tutto</p>
                  </Link>
                  <Link to='/Abbigliamento/Giacche_invernali' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Giacche invernali</p>
                  </Link>
                  <Link to='/Abbigliamento/Maglioni' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Maglioni</p>
                  </Link>
                  <Link to='/Abbigliamento/Felpe' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Felpe</p>
                  </Link> <Link to='/Abbigliamento/Giacche' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Giacche</p>
                  </Link> <Link to='/Abbigliamento/T_shirt' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>T-shirts</p>
                  </Link>
                  <Link to='/Abbigliamento/Pants' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Pants</p>
                  </Link>
                </div>
              )}

              {showSneakersSubMenu && (
                <div className='pl-3'>
                  <p onClick={toggleSneakersSubMenu} style={{ textAlign: 'center' }}><ArrowLeftCircleFill/> <strong>Sneakers</strong></p>
                  <Link to='/Sneakers' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                  <p style={{fontWeight: '900', textDecoration: 'underline', color: 'red' }}>Scopri tutto</p>
                  </Link>
                  <Link to='/Sneakers/Adidas' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Adidas</p>
                  </Link>
                  <Link to='/Sneakers/Nike' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Nike</p>
                  </Link>
                  <Link to='/Sneakers/Jordan' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Jordan</p>
                  </Link>
                  <Link to='/Sneakers/Luxury_shoes' style={{ textDecoration: 'none' }} onClick={toggleNav}>
                    <p>Luxury Shoes</p>
                  </Link>
                  {/* Aggiungi altre sottocategorie */}
                </div>
              )}
            </div>



         
        





    <div className='float-end faq me-4 mt-4 mb-5'>
      <div className='d-flex justify-content-end'>
<span className='pe-1 ps-1' style={{color: 'white'}}>FAQ</span>
</div>
<Link to='Spedizione' style={{textDecoration: 'none'}} onClick={toggleNav}>
<p className='mt-2'>Spedizione</p>
</Link>

<Link to='Politiche_di_reso' style={{textDecoration: 'none'}} onClick={toggleNav}>
<p>Politiche di reso</p>
</Link>

<Link to='Esio_bucks' style={{textDecoration: 'none'}} onClick={toggleNav}>
<p>Esio Bucks</p>
</Link>
</div>


<Link to='Error' style={{textDecoration: 'none'}} onClick={toggleNav}>
<div className='d-flex justify-content-center align-items-center mb-5  pt-0 pb-2' style={{width: '100%', gap: '10px', }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
        </svg>
        <p style={{color: 'black', fontFamily: "'Lilita One','sans-serif'", fontSize: '20px'}} className='mb-0'>Accedi o registrati</p>
    </div>
</Link>

</div>

<Link to='/'>

      <img src={logo} width={75} className="ms-4" alt='okay'/>
      
</Link>

<div className='d-none d-lg-flex lg_navbar '>
<ul className=''>


<Link to='Abbigliamento' style={{textDecoration: 'none'}}>
  <li className='li_none' style={{color: 'white'}}>
    ABBIGLIAMENTO
  </li>
</Link>

  <Link to='Sneakers' style={{textDecoration: 'none'}}>
  <li className='li_none' style={{color: 'white'}}>
    SNEAKERS
  </li>
</Link>

  <Link to='Chisiamo' style={{textDecoration: 'none'}}>
  <li className='li_none' style={{color: 'white'}}>
    CHI SIAMO
  </li>
  </Link>

  <Link to='Dropshipping' style={{textDecoration: 'none'}}>
  <li className='li_none' style={{color: 'white'}}>
    DROPSHIPPING
  </li>
  </Link>

  <Link to='Comeacquistare' style={{textDecoration: 'none'}}>
  <li className='li_none' style={{color: 'white'}}>
    COME ACQUISTARE
  </li>
  </Link>
  
</ul>
</div>



          <Link to='Error' style={{ textDecoration: 'none' }}>
          <div className="d-flex flex-row-reverse">

            <div className="p-2 me-2 ">
              <img src={carrello} height={30}  className="responsive-img" alt='okay' />
            </div>

            <div className="p-2 ">
              <img src={utente} height={30} className="responsive-img" alt='okay' />
            </div>

          </div>
          </Link>
        </nav>
      </div>
      {/*finenavbar*/}

      
    </>
  

    )
}




export default Navbar;

