import './Product.css'
import '../pages/pagescomponents/Productmenu.css'
import React, {useState, useRef} from "react";

import {Link} from 'react-router-dom';

import shoes from '../pages/pagescomponents/images/productimages_slider/p40.png'


function New_product_components(props){


  const scrollContainer = useRef(null);

  const scrollLeft = () => {
    scrollContainer.current.style.scrollBehavior = 'smooth';
    scrollContainer.current.scrollLeft -= 300;
  };

  const scrollRight = () => {
    scrollContainer.current.style.scrollBehavior = 'smooth';
    scrollContainer.current.scrollLeft += 300;
  };


  
    return ( 
            <>
           
         
           <div className='ms-4'>
<img src={props.immagine} width={150} className='pt-3 pb-2'/>

<div className='text-start'>
<p className='mb-0' style={{fontWeight: '900'}}>{props.modello}</p>
<p className='' style={{fontSize: '20px', marginBottom: '3px'}}>{props.brand}</p>
<span>{props.prezzo}</span>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" class="bi bi-currency-euro pb-1" viewBox="0 0 16 16">
        <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"/>
        </svg>
</div>
</div>


</>
    )
}
export default New_product_components;