import Doublewrite from "../components/Doublewrite";
import './Chisiamo.css'
import ContactForm from './pagescomponents/ContactForm'
import {useSpring, animated} from 'react-spring';
import React, { useState, useEffect} from 'react';


function Contatti (){

    const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <>
     <animated.div style={props}>

        <Doublewrite firstwrite='CONTATTI' secondwrite='CONTATTI'/>
        <div className="p-md-4 responsive_contact">
            <h4 style={{fontFamily: "'Nosifer', 'sans-serif'", fontWeight:'900'}} className="ps-3 ps-md-0 mt-4 mt-md-0">Come contattarci?</h4>
        <p className="ps-3 ps-md-0" style={{fontFamily: "'Raleway', 'sans-serif'", fontWeight:'800'}}>A breve sarà disponibile avviare una conversazione direttamente con i nostri agenti. 
            Questo sarà possibile una volta che il nostro staff avrà finito di sviluppare la tua area riservata per permetterti di creare un account</p>
            <h5 className="mt-5 ps-3 ps-md-0" style={{fontFamily: "'Nosifer', 'sans-serif'", fontWeight:'900'}}>Ora però come fare?</h5>
            <p className="mb-5 ps-3 ps-md-0" style={{fontFamily: "'Raleway', 'sans-serif'", fontWeight:'800'}}>
                Per contattarci puoi scriverci direttamente sul nostro profilo Instagram, compilare il form che trovi nel footer in basso, oppure scrivere direttamente una mail al nostro indirizzo:<br></br><br></br> shoppesio.assistenza@outlook.it  <br></br><br></br>Il nostro staff ti risponderà nel più breve tempo possibile!
            </p>
    
        </div>
</animated.div>
        </>
    )
}

export default Contatti;