import './Doublewrite.css'

function Doublewrite (props) {


  
    return (
        <>
        <div className="container3 mt-4 mt-lg-5 mb-3 mb-0 ">
        <div className="text-box mb-0">
          <h1 className="h1card">{props.firstwrite}</h1>
          <h1 className="h1card">{props.secondwrite}</h1>
        </div>
      </div>
      
      </>

    )
}

export default Doublewrite;