import { Link } from 'react-router-dom';
import './Sliderproduct.css'
import Sliderproduct_components from './Sliderproduct_components'


function Sliderproduct({title, products}){

    return ( 
     <div className='"margine mt-2 pt-3  mt-lg-5'>
      <p className='lastsneakers ps-3'>{title}</p>
        <div className= 'overflow-scroll'>
  <ul className="images mt-2 ms-3">
  {products.map((product) => ( // Mappa i dati dei prodotti
  <Link to={`/Abbigliamento/${product.id}`} style={{textDecoration: 'none', color: 'black'}} key={product.id}>
    <Sliderproduct_components key={product.id} modello={product.modello} brand={product.brand} prezzo={product.prezzo} immagine={product.immagine}/>
  </Link>
))}
 

  
    
  </ul>
</div>
</div>   


    )
}
export default Sliderproduct;