import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Productmenu.css';

function Productmenu(props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAbbigliamentoOpen, setIsAbbigliamentoOpen] = useState(false);
  const [isSneakersOpen, setIsSneakersOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    // Close submenus when the sidebar is closed
    setIsAbbigliamentoOpen(false);
    setIsSneakersOpen(false);
  };

  const toggleAbbigliamento = () => {
    setIsAbbigliamentoOpen(!isAbbigliamentoOpen);
  };

  const toggleSneakers = () => {
    setIsSneakersOpen(!isSneakersOpen);
  };

  return (
    <>
      <nav className='d-flex justify-content-start product_menu p-2 align-items-center' onClick={toggleSidebar}>
        <span className='ms-4'>FILTRI</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" className="bi bi-filter-circle-fill ms-3" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M3.5 5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1M5 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
        </svg>
      </nav>

      <div className={`sidebar_product ${isSidebarOpen ? 'open' : ''}`}>
        <span className="close-btn" onClick={closeSidebar}>&times;</span>
        <p className='mt-1' style={{color:'black', textDecoration: 'underline'}}>Seleziona la tua categoria!</p>

        <p style={{ backgroundColor: 'black', position: 'relative' }}>
          <p onClick={toggleAbbigliamento} style={{ cursor: 'pointer', marginBottom: '0px', paddingTop: '10px', paddingBottom: '10px', textDecoration: 'underline'}}>
            ABBIGLIAMENTO {isAbbigliamentoOpen ? '▲' : '▼'}
          </p>

          {isAbbigliamentoOpen && (
            <div className="submenu">
              {/* Add submenu content for ABBIGLIAMENTO */}
              <Link to="/Abbigliamento/Maglioni">Maglioni</Link>
              <Link to="/Abbigliamento/Felpe">Felpe</Link>
              <Link to="/Abbigliamento/Pants">Pants</Link>
              <Link to="/Abbigliamento/Giacche">Giacche</Link>
              <Link to="/Abbigliamento/Giacche_invernali">Giacche invernali</Link>
              <Link to="/Abbigliamento/T-shirts">T-shirts</Link>

            </div>
          )}
        </p>

        <p style={{ backgroundColor: 'black', position: 'relative' }}>
          <p onClick={toggleSneakers} style={{ cursor: 'pointer', marginBottom: '0px', paddingTop: '10px', paddingBottom: '10px', textDecoration: 'underline'}}>
            SNEAKERS {isSneakersOpen ? '▲' : '▼'}
          </p>

          {isSneakersOpen && (
            <div className="submenu">
              {/* Add submenu content for SNEAKERS */}
              <Link to="/Sneakers/Nike">Nike</Link>
              <Link to="/Sneakers/Jordan">Jordan</Link>
              <Link to="/Sneakers/Adidas">Adidas</Link>
              <Link to="/Sneakers/Luxury_shoes">Luxury Shoes</Link>


            </div>
          )}
        </p>
      </div>
    </>
  );
}

export default Productmenu;
