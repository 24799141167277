import '../Abbigliamento.css'
import antisocial from '../pagescomponents/images/productimages/p101_dietro.png';
import helloim from './images/helloantosocial.jpg';
import antisocialhoodie from '../pagescomponents/images/productimages/p101.png';

function Antisocialclub () {
    return (

<div className='d-flex justify-content-evenly flex-row-reverse align-items-center flex-wrap mt-5 mb-5 bg-warning p-5'>
    <div className='d-column'>
    <img src={helloim} width={120} className='mx-auto d-block rounded' />
    <h3 className='h2graphics p-2 mt-4' style={{fontFamily: "'Nosifer', 'sans-serif'", color: 'white', backgroundColor: 'black'}}>ANTISOCIAL CLUB</h3>

    </div>
    <div className='d-column  border border-4 border-dark mt-3 bg-light '>
    <img src={antisocial} className='p-1 mx-auto rounded d-block' width={210} />
    </div>
    
    <div className='d-column  border border-4 border-dark mt-4 bg-light'>
    <img src={antisocialhoodie} className='p-1 mx-auto rounded d-block' width={210} />
    </div>
    
</div>

    )
}

export default Antisocialclub;