import './Cuffie.css'
import louisvuitton_bag from './immagini/louisvuitton_bag.png'
import coin from './immagini/Progetto senza titolo (1).png'
import { Link } from 'react-router-dom'

function Cuffie (){
    return (
            <>
            <div className=' d-lg-flex justify-content-evenly align-items-center mt-5'>
                <div className='d-column container containermedia bg-dark ms-lg-4 '>
                <h5 className='h4cuffie pt-3 pb-2'>Scopri come vincere uno zaino di Louis Vuitton accumulando<br></br><h4 className='h4cuffie text-uppercase mt-1' style={{color:'orange', textDecoration: 'underline'}}>"Esio Bucks"</h4></h5>
                <img className='rounded mx-auto d-block pb-2 pt-2' src={louisvuitton_bag} width={250} />
                <div className='d-flex justify-content-center pb-3 mt-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="white" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
</svg>
</div>
                </div>
                
                <div className='d-column mediatext p-4'>
                    
                    <h4 className='h4cuffie mb-4' style={{color:'red', textDecoration:'underline'}}>COSA SONO GLI "ESIO BUCKS"? </h4>
                    <h6 className='h4cuffie' style={{color:'black'}}>Gli ESIO BUCKS sono il nostro metodo per essere riconoscenti e premiare gli utenti che hanno creduto fin da subito nel nostro progetto! </h6>
                    <img src={coin} width={120} className='mt-3 mx-auto d-block rounded' />
                    <h5 className='h4cuffie text-uppercase mt-3 text-decoration-underline' style={{color:'black'}}>Scopri di più nella sezione FAQ
                    <Link to='FAQ' style={{textDecoration: 'none', color: 'white'}}>
                    <button className='btn btn-primary m-auto mt-4 mb-4 ms-lg-5' style={{fontFamily: "'Raleway', 'sans-serif'",
                        fontWeight: '900', fontSize: '20px',}}>FAQ/ESIO BUCKS</button> </Link></h5>
                  
                </div>
            </div>
       
        </>
    )
}

export default Cuffie;