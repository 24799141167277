import Doublewrite from "./Doublewrite"
import work_in_progress from './immagini/workinprogress_icon.png'
import './Product.css'
import '../pages/pagescomponents/Productmenu.css';
import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import FollowonIG from "../pages/pagescomponents/FollowonIG";

import {Link} from 'react-router-dom';
import logo from '../pages/pagescomponents/images/logo.png';

function Error (){



    const [show, setShow] =useState(false);
    const handleShow=() => {setShow(true)}
    const handleClose=() => {setShow(false)}
  

    const [isAbbigliamentoMenuOpen, setAbbigliamentoMenuOpen] = useState(false);
    const [isSneakersMenuOpen, setSneakersMenuOpen] = useState(false);
  
    const toggleAbbigliamentoMenu = () => {
      setAbbigliamentoMenuOpen(!isAbbigliamentoMenuOpen);
      setSneakersMenuOpen(false);
    };
  
    const toggleSneakersMenu = () => {
      setSneakersMenuOpen(!isSneakersMenuOpen);
      setAbbigliamentoMenuOpen(false);
    };

    return(
        <>
            <Doublewrite firstwrite='LOGIN PAGE'secondwrite='LOGIN PAGE'/>

          
            <div className="d-flex justify-content-center mt-3">
                <img src={work_in_progress} width={120} />           
            </div>

            <div className="text-center mt-3 mb-5">
            <   span className="pe-1 ps-1" style={{fontFamily: "'Nosifer', 'sans-serif", fontSize: '20px', color: 'white', backgroundColor:'black'}}>WORK IN PROGRESS</span>
            </div>
            
            <div className="d-flex justify-content-center mt-3 flex-wrap">
                <span className="text-start ms-4 me-3" style={{fontFamily:"'Raleway', 'sans-serif'", fontSize: '17px', fontWeight:'900'}}>Il tuo spazio riservato è in arrivo! 
                A breve, potrai creare il tuo account personalizzato qui su Sh0pp3sio e vivere un'esperienza di shopping ancor più personalizzata. 
                <br></br>Resta con noi per scoprire tutte le novità e goditi il nostro mondo di offerte esclusive.</span>
                <span className="text-uppercase text-decoration-underline  ms-4 me-3 mt-3" style={{fontFamily:"'Bebas Neue', 'sans-serif'", fontSize: '22px', fontWeight:'900', color:'red'}}>Non vediamo l'ora di darti il benvenuto nell'area riservata di Sh0pp3sio!</span>            
            </div>
            <div className="d-flex justify-content-center mt-5">
                <button className="btn btn-primary" style={{fontFamily: "'Nosifer', 'sans-serif'"}} onClick={handleShow}>CONTINUA LO SHOPPING</button>
            </div>
            <div className="d-flex justify-content-center mt-2 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
            <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
            </svg>
            </div>

            <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className='navproduct' closeVariant="white">
  <Modal.Title ><span>YOUR STREETWEAR</span></Modal.Title>
</Modal.Header>
        <Modal.Body>
          <div className='off-canvas-body'>
            <div className='d-flex justify-content-center align-items-center' onClick={toggleAbbigliamentoMenu}>
              <h3 className='mt-3' >ABBIGLIAMENTO</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" className="bi bi-arrow-bar-down pt-1 ms-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
              </svg>
            </div>
            {isAbbigliamentoMenuOpen && (
              <div className='mt-4' >
                {/* Sottocategorie per ABBIGLIAMENTO */}
                <Link to="/Abbigliamento/T_shirt" style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark '>T-SHIRT</p></Link>
                <Link to="/Abbigliamento/Giacche" style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>GIACCHE</p></Link>
                <Link to="/Abbigliamento/Felpe" style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>FELPE</p></Link>
                <Link to="/Abbigliamento/Maglioni"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>MAGLIONI</p></Link>
                <Link to="/Abbigliamento/Pants"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>PANTS</p></Link>
                <Link to="/Abbigliamento/Giacche_invernali"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2 mb-3'>GIACCHE INVERNALI</p></Link>
              </div>
            )}
            <div className='d-flex justify-content-center align-items-center mb-4' onClick={toggleSneakersMenu}>
              <h3 className='mt-3 '>SNEAKERS</h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" className="bi bi-arrow-bar-down pt-1 ms-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
              </svg>
            </div>
            {isSneakersMenuOpen && (
              <div className='mt-4'>
                {/* Sottocategorie per SNEAKERS */}
                <Link to="/Sneakers/Nike"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark'>NIKE</p></Link>
                <Link to="/Sneakers/Jordan"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>JORDAN</p></Link>
                <Link to="/Sneakers/Adidas"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2'>ADIDAS</p></Link>
                <Link to="/Sneakers/Luxury_shoes"style={{textDecoration:'none', color: 'black'}}><p className='border border-2 border-dark mt-2 mb-5'>LUXURY'S SHOES</p></Link>
              </div>
            )}
            <FollowonIG />
          </div>
        </Modal.Body>
      </Modal>


        </>
    )
}

export default Error;