import './Home.css';
import Ourmainbrand from '../components/Ourmainbrand';
import Sliderproduct from '../components/Sliderproduct';
import New_product from '../components/New_product';
import Blob from '../components/Blob';
import Evidence from '../components/Evidence';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap"; // <-- JS File
import Cuffie from '../components/Cuffie';
import Doublewrite from '../components/Doublewrite';
import warning_sign from './pagescomponents/images/warningsign.png';
import { useProductContext } from './ProductContext';
import {useSpring, animated} from 'react-spring'
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Sale_of_week from '../components/Sale_of_week';
import Home_chisiamo_dropshipping from './pagescomponents/Home_chisiamo_dropshipping';
import Fourproduct from './pagescomponents/Fourproduct';

import p1 from  './pagescomponents/images/productimages/p1.png'      // Immagine del fronte
import p2 from './pagescomponents/images/productimages/p2.png' // Immagine del fronte
import Felpe from '../components/immagini/hoodies.png'
import t_shirt from '../components/immagini/t-shirt.png'
import downjacket from '../components/immagini/downjacket.png'
import jackets from '../components/immagini/jackets.png'
import maglioni from '../components/immagini/maglioni.png'
import pants from '../components/immagini/pants.png'
import immagine10 from '../components/immagini/scontitino2.png';
import immagine11 from '../components/immagini/scontitino1.png';
import Productmenu from './pagescomponents/Productmenu';

function Home (){

  const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  const { products } = useProductContext();



const trendProducts = products.filter(product => product.trend === true);

// Seleziona solo i primi 6 prodotti
const selectedProducts = trendProducts;



    return (
            <>
             <animated.div style={props}>
            <Blob/>
          
            <div className=''>
                <img src={immagine11} className=' img-fluid d-md-none'/>
              </div> 

            <Doublewrite firstwrite='OUR STREETWEAR' secondwrite='OUR STREETWEAR'/>
            <div className='home_responsive'>
            <New_product title="Trending Item" products={selectedProducts}/> {/* Passa i prodotti selezionati come props */}

            </div>
            <div className=''>
                <img src={immagine10} className='d-none d-md-block img-fluid'/>
              </div> 
            <Evidence/>
          
            <Sale_of_week/>
            <div className='mt-5 mb-5'>
              <p className='text-center mb-5' style={{fontFamily: "'Lilita One', 'sans-serif'", fontSize: '25px'}}>ABBIGLIAMENTO</p>
            <div className=' d-flex flex-wrap justify-content-evenly align-items-center'>

            <Link to='Abbigliamento/Felpe' >
            <img src={Felpe} width={160}  />
            </Link>

            <Link to='Abbigliamento/T_shirt' >
            <img src={t_shirt} width={160}  />
            </Link>

            <Link to='Abbigliamento/Giacche' >
            <img src={jackets} className='mt-5 mt-md-0'  width={160}  />
            </Link>

            <Link to='Abbigliamento/Giacche_invernali' >
            <img src={downjacket} className='mt-5 mt-md-0'   width={160}  />
            </Link>

            <Link to='Abbigliamento/Maglioni' >
            <img src={maglioni}  className='mt-5 mt-md-0'   width={160}  />
            </Link>

            <Link to='Abbigliamento/Pants' >
            <img src={pants}  className='mt-5 mt-md-0'   width={160}  />
            </Link>

            </div>
            </div>


            <Fourproduct/>
            <Home_chisiamo_dropshipping/>
            
              
           

           <Ourmainbrand/>
           </animated.div>
            </>
          );
}

export default Home;