import './Ourmainbrand.css'
import Doublewrite from '../components/Doublewrite';
import lv from './loghi/marchio-louis-vuitton.png'
import tnf from './loghi/the-north-face.png'
import gucci from './loghi/1960s_Gucci_Logo.svg.png'
import bape from './loghi/bape-logo-bape-collab-transparent.png'
import moncler from './loghi/Moncler-Logo.png'
import ralph from './loghi/ralph-lauren-logo-vector-editorial-260nw-2336062305.png'
import nike from './loghi/adesivi-murali-logo-nike.png'
import tommy from './loghi/Tommy-Hilfiger-logo.png'
import jordan from './loghi/jordan.png'
import stussy from './loghi/stussy.png'
import antisocial from './loghi/antisocial.png'
import adidas from './loghi/adidas.png'

function Ourmainbrand (){


    const firstwrite = 'OUR MAIN BRAND'
    const secondwrite= 'OUR MAIN BRAND'


    return(
        <>
        <div className='mt-0'>
        <Doublewrite firstwrite={firstwrite} secondwrite={secondwrite} />
        <hr className='hrmainbrand border border-2 border-warning'></hr>
        <div className='d-flex justify-content-evenly align-items-center flex-wrap'>
            <img src={lv} width={90}/>
            <img src={tnf} width={90}/>
            <img src={gucci} width={75}/>
            <img src={bape} width={90}/>
            <img src={moncler} width={90}/>
            <img src={ralph} width={90}/>
            <img src={tommy} width={110}/>
            <img src={nike} width={80}/>
            <img src={jordan} width={90}/>
            <img src={adidas} width={100}/>
            <img src={antisocial} width={90}/>
            <img src={stussy} width={85}/>


        </div>
        <hr className='hrmainbrand border border-2 border-warning'></hr>
        </div>
        </>
    )
}

export default Ourmainbrand;