import './Sale_of_week.css'
import '../pages/pagescomponents/Productmenu.css'
import React, {useState} from "react";
import { motion } from 'framer-motion';
import Productcomponents from './Productcomponents';
import {Link} from 'react-router-dom';
import { useProductContext } from '../pages/ProductContext';
import { TransitionGroup, CSSTransition } from 'react-transition-group';



function Sale_of_week(){
        const { products } = useProductContext();
        const [currentIndex, setCurrentIndex] = useState(products.length - 2);
      
        const handlePrev = () => {
          if (currentIndex < products.length - 2) {
            setCurrentIndex(oldIndex => oldIndex + 2);
          }
        };
      
        const handleNext = () => {
          if (currentIndex > products.length - 16) {
            setCurrentIndex(oldIndex => oldIndex === 0 ? 0 : oldIndex - 2);
          }
        };
      
        return (
          <>
            <div className='news_of_the_week mt-5'>
              <h4>NEWS OF THE WEEK</h4>
              <div className='d-flex  justify-content-center align-items-center'>
                <svg onClick={handlePrev} xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="black" class="bi bi-chevron-compact-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                </svg>
                <motion.div key={products[currentIndex].id} initial={{opacity: 0, x: -100}} animate={{opacity: 1, x: 0}} exit={{opacity: 0, x: 100}} transition={{duration: 0.75}}>
          <Link to={`/Abbigliamento/${products[currentIndex].id}`} style={{textDecoration: 'none', color: 'black'}}>
            <Productcomponents className='ms-0' immagine={products[currentIndex].immagine} prezzo={products[currentIndex].prezzo} brand={products[currentIndex].brand} categoria={products[currentIndex].categoria}  />
          </Link>
        </motion.div>
        <motion.div key={products[(currentIndex - 1 + products.length) % products.length].id} initial={{opacity: 0, x: -100}} animate={{opacity: 1, x: 0}} exit={{opacity: 0, x: 100}} transition={{duration: 0.5}}>
          <Link to={`/Abbigliamento/${products[(currentIndex - 1 + products.length) % products.length].id}`} style={{textDecoration: 'none', color: 'black'}}>
            <Productcomponents immagine={products[(currentIndex - 1 + products.length) % products.length].immagine} prezzo={products[(currentIndex - 1 + products.length) % products.length].prezzo} brand={products[(currentIndex - 1 + products.length) % products.length].brand} categoria={products[(currentIndex - 1 + products.length) % products.length].categoria}  />
          </Link>
        </motion.div>

                <svg onClick={handleNext} xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="black" class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                </svg>
              </div>
            </div>
          </>
        )
      }
      
      export default Sale_of_week;