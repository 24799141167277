import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './WelcomePopup.css'
import Logo from './immagini/logo.png'
import warning_sign from '../pages/pagescomponents/images/warningsign.png';
import {Link} from 'react-router-dom';

function WelcomePopup() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} >
        <div className='WelcomePopup' >
        <Modal.Header closeButton>
          <Modal.Title><h4 className='pt-2'>BENVENUTO SU SH0PP3SIO</h4></Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <img src={warning_sign} width={60} className='mx-auto d-block'/>
        <p className='text-center text-uppercase mt-2'>Avviso per i nostri utenti!</p>
        <span className='text-center mt-2'>Al momento il sito funge solo da catalogo, perciò non è ancora possibile acquistare i nostri articoli direttamente dal sito!</span>
        <br></br>
        <p className='text-start text-uppercase mt-3 mb-0'>Quindi come è possibile acquistare i nostri articoli?</p>
        <span className='text-center mt-2'>Scopri navigando la nostra sezione 'COME ACQUISTARE'</span>

        <Link to='Comeacquistare' style={{textDecoration: 'none'}}>
        <button className='btn d-block mt-2 buttonpopup' onClick={handleClose}>COME ACQUISTARE</button>
        </Link>
       

        </Modal.Body>
      
        </div>
      </Modal>
    </>
  );
}

export default WelcomePopup;