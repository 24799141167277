import './Dropshipping.css'
import Doublewrite from '../components/Doublewrite'
import dropshipping from './pagescomponents/images/dropshipping.png'
import shipstreetwear from './pagescomponents/images/shipstreetwear.png'
import {useSpring, animated} from 'react-spring';
import React, { useState, useEffect} from 'react';
import dropshipping_scheme from '../components/immagini/dropshipping_scheme.png'

function Dropshipping (){

    const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      

    return(
            
            <>
          
          <animated.div style={props}>
          <div className='container_home text_dropshipping'>
          <Doublewrite firstwrite='DROPSHIPPING' secondwrite='DROPSHIPPING'/>


          <h4 className='text-center textresponsive mb-md-4  mt-4' >
                    COS'E' IL DROPSHIPPING?          </h4>
                    <img src={dropshipping_scheme}  className='mx-auto d-block pt-3 img-fluid ps-3 pe-3'/>
                    <p className='m-4' >Con Dropshipping intendiamo quel processo che permette di vendere prodotti online senza disporre di magazzini fisici! Siamo specializzati nel ricercare fornitori e produttori unici in modo da offrirti la moda Urban a prezzi mai visti! Una volta che hai deciso quale articolo comprare, questo verrà spedito dal fornitore direttamente in uno dei nostri magazzini di riferimento, che a sua volta verrà spedito direttamente al tuo indirizzo! </p>


           
                <h4 className='text-center textresponsive mb-md-5 mt-md-5' >
                    PERCHE' IL DROPSHIPPING?
                </h4>
            <div className='d-md-flex justify-content-center align-items-center  imgdrop_1'>
                <div className='d-column ps-md-4'>
                <img src={dropshipping} className='mx-auto d-block' />
                </div>

                <div className='d-column p-4 pe-3 p-md-5'>
                <span >
                Il dropshipping è la magia che alimenta il nostro e-commerce di streetwear. Invece di avere un magazzino pieno di abbigliamento, collaboriamo con fornitori specializzati. <br></br>Quando un cliente ordina un prodotto, il fornitore lo spedisce direttamente a casa sua. Nessuna scorta in eccesso, nessun rischio. 
                <br></br><span className='border border-dark border-2'> Noi possiamo concentrarci sulla creazione di stile, e tu ricevi i tuoi capi preferiti direttamente a casa! </span>
                </span>
                </div>
            </div>

            <h4 className='text-center textresponsive mb-md-4  mt-md-5' >
                    QUESTO COME INFLUISCE SULLA SPEDIZIONE?                </h4>

                    <div className='d-md-flex align-items-center'>

                    

                    <div className='d-column pe-4 ps-3 p-md-5'>
                    <span>Purtroppo il nostro sistema di fornitori terzi (dropshippping) determina tempi di spedizione più lunghi di qualche giorno, questo perchè prima di far partire la spedizione dai nostri magazzini di riferimento 
                    dobbiamo attendere che il nostro fornitore spedisca gli articoli e arrivino pressi i nostri magazzini.<br></br>
                    <span className='border border-dark border-2'>Nonostante il tempo aggiuntivo,
                     garantiamo che ne varrà la pena quando riceverai il tuo streetwear di alta qualità direttamente a casa tua. </span></span>
                    </div>

                    <div className='d-column imgdrop'>
                    <img src={shipstreetwear} className='mx-auto d-block'width={200}/>
                    </div>
                        
                    </div>
                   
                    </div>
</animated.div>
            </>

    )
}

export default Dropshipping;