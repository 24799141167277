import '../Home.css'
import Logo from './images/logo.png';
import download4 from './images/download (4).jpg'
import dropshipping from './images/dropshipping.png'
import dropshipping_scheme from '../../components/immagini/dropshipping_scheme.png'
import { Link } from 'react-router-dom';


function Home_chisiamo_dropshipping (){

    return (
            <><div className='d-md-flex justify-content-evenly  align-items-center home_info mt-5 d-md-none'>

            <div className='flex-column rounded m-4 m-md-5 mb-5 half_column' style={{ backgroundColor: 'black' }}>
                <div className='bg-warning'>
                    <h5 className='p-2 pe-3 ps-3 pt-3 pb-2 text-center' style={{ color: 'black' }}>
                        CHI SIAMO?
                    </h5>
                </div>
                <div className='m-3 mt-4'>
                    <img src={download4} width={130} className='mx-auto d-block mb-4' />
                    <p className='text-start'>
                        SH0PP3SIO, creato nel 2019 da appassionati di streetwear, è un e-commerce che offre capi e accessori di alta qualità
                        ispirati alle ultime tendenze urbane.
                        Punto di riferimento per chi cerca personalità e originalità nel proprio stile, il sito presenta le migliori marche,
                        prodotti esclusivi e un tocco di freschezza per un look moderno e confortevole.
                    </p>
                    <img src={Logo} width={70} className='mx-auto d-block ' />
                    <div className='d-flex justify-content-center pb-4 mt-4'>
                        <Link to='Chisiamo' style={{ textDecoration: 'none' }}>
                            <button className='btn btn-warning'>SCOPRI DI PIU'</button>
                        </Link>
                    </div>



                </div>
            </div>

            <div className='flex-column rounded m-4 m-md-5 mb-5 bg-warning half_column '>
                <div className='' style={{ backgroundColor: 'black' }}>
                    <h5 className='p-2 pe-3 ps-3 pt-3 pb-2 text-center' style={{ color: 'white' }}>
                        DROPSHIPPING
                    </h5>
                </div>
                <div className='m-3 mt-4'>
                    <img src={dropshipping} width={170} className='mx-auto d-block mb-4' />
                    <p className='text-start' style={{ color: 'black' }}>
                        Il nostro e-commerce di streetwear utilizza il dropshipping, eliminando la necessità di un magazzino.
                        Collaboriamo con fornitori specializzati: quando un cliente ordina, il prodotto viene spedito direttamente dal fornitore al cliente.
                        Senza eccesso di scorte o rischi,
                        ci concentriamo sulla creazione di stile, garantendo che i tuoi capi preferiti arrivino direttamente a casa tua.
                    </p>

                    <img src={dropshipping_scheme} width={230} className='mx-auto d-block mt-4' />
                    <Link to='Dropshipping' style={{ textDecoration: 'none' }}>
                        <div className='d-flex justify-content-center  mb-4 mt-4 pb-4 pt-2'>
                            <button className='btn' style={{ backgroundColor: 'black', color: 'white' }}>SCOPRI DI PIU'</button>
                        </div>
                    </Link>


                </div>
            </div>

        </div>

            <div className='m-4 mt-5 mb-5 d-none d-md-flex align-itmes-center justify-content-evenly responsive_md_chisiamo' style={{gap: '20px',}}>
                    <div style={{backgroundColor: 'black', color: 'white'}} className='p-3 pb-4'>
                        <h3>Chi siamo?</h3>
                        <div className='border-top border-white border-3 d-flex flex-wrap justify-content-center'>
                            <p className='pt-3'>
                        SH0PP3SIO, creato nel 2019 da appassionati di streetwear, è un e-commerce che offre capi e accessori di alta qualità
                        ispirati alle ultime tendenze urbane.
                        Punto di riferimento per chi cerca personalità e originalità nel proprio stile, il sito presenta le migliori marche,
                        prodotti esclusivi e un tocco di freschezza per un look moderno e confortevole.
                           </p>
                           <div className='w-100 d-flex justify-content-center mb-4'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
</svg>
                            </div>
                            <Link to='Chisiamo' style={{textDecoration:'none'}}>
                        <button className='btn-warning btn'>
                            Chi siamo?
                        </button>
                        </Link>
                        </div>
                    </div>

                    <div style={{backgroundColor: 'black',  color: 'white'}}  className='p-3'>
                        <h3>Dropshipping</h3>
                        <div className='border-top border-white border-3 d-flex flex-wrap justify-content-center'>
                            <p className='pt-3'>
                        Il nostro e-commerce di streetwear utilizza il dropshipping, eliminando la necessità di un magazzino.
                        Collaboriamo con fornitori specializzati: quando un cliente ordina, il prodotto viene spedito direttamente dal fornitore al cliente.
                        Senza eccesso di scorte o rischi,
                        ci concentriamo sulla creazione di stile, garantendo che i tuoi capi preferiti arrivino direttamente a casa tua.
                            </p>
                            <div className='w-100 d-flex justify-content-center mb-4'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-down-circle-fill mt-4 mt-xl-0" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
</svg>
                            </div>
                            <Link to='Dropshipping' style={{textDecoration:'none'}}>
                        <button className='btn-warning btn'>
                            Dropshipping
                        </button>
                        </Link>
                        </div>
                    </div>
            </div>
            </>







    )
}

export default Home_chisiamo_dropshipping