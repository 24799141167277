import Doublewrite from './Doublewrite';
import './Product.css'
import bucks from './immagini/Progetto senza titolo (1).png'
import spedizione from '../pages/pagescomponents/images/shipstreetwear.png'
import reso from '../pages/pagescomponents/images/reso.png'
import {useSpring, animated} from 'react-spring'
import louisvuitton_bag from './immagini/louisvuitton_bag.png'
import React, { useState, useEffect} from 'react';


function Politiche_di_reso (){


const props = useSpring({from: {marginTop: -500}, to: {marginTop: 0}});
useEffect(() => {
window.scrollTo(0, 0);
}, []);
  


    return(
        <>

    <animated.div style={props}>

    <div className='spedizione'>
        <Doublewrite firstwrite='POLITICHE DI RESO' secondwrite='POLITICHE DI RESO'/>

        <p style={{fontFamily: "'Nosifer', 'sans-serif'", fontSize: '17px', fontWeight: '900'}} className='text-center mt-4'>Come lavoriamo?</p>


            <div className='ms-3 mt-4 me-2'>
            <span className='p-1' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800', color: 'white', backgroundColor: 'black'}}>
               Accettiamo resi su SHOPPESIO?</span>
                <div className='d-flex justify-content-center mb-3 mt-3'>
            <img src={reso} width={150} />
        </div>
                <span  className='border border-2 border-dark ' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>Su SHOPPESIO è possibile effettuare un reso solo se almeno una tra queste condizioni è soddisfatta:
                </span>
                <ul className='mt-3 mb-4' style={{fontFamily:"'Raleway', 'sans-serif'", fontWeight:'800',}}>

                    <li >a) Il prodotto consegnato è diverso da quello acquistato
                    </li>
                    <li className='mt-2'>b) La taglia del prodotto consegnato è diversa da quella selezionata dal cliente al momento dell'acquisto
                    </li>
                    <li className='mt-2'>c) Il prodotto è stato consegnato e risulta danneggiato o presenta evidenti difetti
                    </li>
                    <li className='mt-2'>d) Il prodotto impiega più di 30 giorni per arrivare a destinazione
                    </li>
                </ul>
            
            
            </div>
        
</div>
    </animated.div>

        </>
    )
}


export default Politiche_di_reso;