import instaicon from './immagini/Instagram-Icon.png'
import './Footer.css'; // Importa il tuo file CSS
import {Link} from "react-router-dom"
import ContactForm from '../pages/pagescomponents/ContactForm';
function Footer (){
    return (
<>
  <footer className=" footer mt-4">
    <div className='footer_2'>

    <div className="text-center pt-3">
      <span
        style={{ color: "white", fontFamily: '"Anton", sans-serif' }}
        className="text-center pt-3"
      >
        SH0PP3SIO.COM
      </span>
<div className='d-flex justify-content-center mt-2 mb-1'>
      <img src={instaicon} width={35} className=" " />
</div>
      <p

        style={{
          color: "white",
          fontFamily: '"Raleway", sans-serif',
          fontWeight:'800',
          textDecoration: "overline"
        }}
        className="text-center pt-3 ms-1 me-1"
      >
        Vieni a trovarci sulla nostra pagina Instagram!
      </p>
    </div>
    


<div className='d-flex justify-content-around align-items-start mt-5 footer_list'>
  <div>
    <h4>CATALOGO</h4>
    <ul className='p-0' style={{ listStyle: 'none' }}>
      <Link to='/Abbigliamento/Maglioni' style={{ textDecoration: 'none' }}>
        <li>
          Maglioni
        </li>
      </Link>
      <Link to='/Abbigliamento/Felpe' style={{ textDecoration: 'none' }}>
        <li>
          Felpe
        </li>
      </Link>
      <Link to='/Abbigliamento/Giacche-invernali' style={{ textDecoration: 'none' }}>
        <li>
          Giacche invernali
        </li>
      </Link>
      <Link to='/Abbigliamento/Giacche' style={{ textDecoration: 'none' }}>
        <li>
          Giacche
        </li>
      </Link>
      <Link to='/Abbigliamento/T_shirt' style={{ textDecoration: 'none' }}>
        <li>
          T-shirts
        </li>
      </Link>
      <Link to='/Abbigliamento/Pants' style={{ textDecoration: 'none' }}>
        <li>
          Pants
        </li>
      </Link>
      <Link to='/Sneakers' style={{ textDecoration: 'none' }}>
        <li>
          Sneakers
        </li>
      </Link>
    </ul>
  </div>
  <div>
    <h4>LINK UTILI</h4>
    <ul className='p-0' style={{ listStyle: 'none' }}>
      <Link to='/Politiche_di_reso' style={{ textDecoration: 'none' }}>
        <li>
          Politiche di reso
        </li>
      </Link>
      <Link to='/Spedizione' style={{ textDecoration: 'none' }}>
        <li>
          Spedizione
        </li>
      </Link>
      <Link to='/Esio_bucks' style={{ textDecoration: 'none' }}>
        <li>
          Esio Bucks
        </li>
      </Link>
      <Link to='/Dropshipping' style={{ textDecoration: 'none' }}>
        <li>
          Dropshipping
        </li>
      </Link>
      <Link to='/Comeacquistare' style={{ textDecoration: 'none' }}>
        <li>
          Come acquistare
        </li>
      </Link>
      <Link to='/Chisiamo' style={{ textDecoration: 'none' }}>
        <li>
          Chi siamo
        </li>
      </Link>
      <Link to='/Contatti' style={{ textDecoration: 'none' }}>
        <li>
          Contattaci
        </li>
      </Link>
    </ul>
  </div>
</div>;





                <div className='mt-3'>
                  <p className='ps-4 mb-0 ' style={{color: 'white', fontFamily: "'Lilita One','sans-serif'", fontSize: '18px'}}>Contattaci compilando questo form!</p>
               <ContactForm/>
               </div>

               
                



</div>

  <div
      className="text-center p-2 bg-warning"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", color: "black", fontFamily:"'Anton','sans-serif'" }}
    >
      © 2023 Copyright: 
        sh0pp3sio.com
      
    </div>
  
  </footer>
</>
    )
}
export default Footer;
