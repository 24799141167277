
import palmangels from './images/Palm Angels - Logo-print Jersey Track Pants - Mens - Black.jpg';
import bapeimg from './images/Yeezy Bape wallpaper by Xwalls - Download on ZEDGE™ _ fd53.jpg';
import trapstar from './images/download.jpg';
import techwp from './images/Nike Tech Fleece Hoodie.jpg';
import '../Abbigliamento.css';

function Fourproduct() {

return (
<div className='container-md '>

    <h2 className='pt-2 h2graphics text-start ps-4 pb-2 mb-3 text-decoration-underline'>Our streetwear selection</h2>
            
<div className='d-flex justify-content-evenly align-items-start flex-wrap productgrid'>
    <div className='d-column me-2'>
        <img src={techwp} width={160} className='rounded' />  
        <h2 className='h2graphics mt-2'>NIKE TECH</h2>
    </div>
    <div className='d-column'>
        <img src={palmangels} width={160} className='rounded' />  
        <h2 className='h2graphics mt-2'>PALM ANGELS</h2>
    </div>
    <div className='d-column me-2'>
        <img src={bapeimg} width={160} className='rounded' />  
        <h2 className='h2graphics mt-2'>BAPE</h2>
    </div>
    <div className='d-column'>
        <img src={trapstar} width={160} className='rounded' />  
        <h2 className='h2graphics mt-2'>TRAPSTAR</h2>
    </div>

    </div>
</div>
)
}

export default Fourproduct;