import React from 'react';
import Nav from 'react-bootstrap/Nav';

function Filtermenu({ onBrandChange }) {

  const handleBrandClick = (brand) => {
    onBrandChange(brand);
  };

  const handleCancelClick = () => {
    onBrandChange(''); // Passa una stringa vuota per annullare il filtro
  };

  return (
    <>
      <div className='dropwdown m-0 p-0'>
        <Nav style={{ backgroundColor: 'black' }} className='mt-0 ps-3 d-flex align-items-center justify-content-end pt-1 pb-1'>
          <p style={{ color: 'white' , fontSize: '20px'}} className='p_category mb-0 me-0'>SELEZIONA BRAND</p>

          <button class="btn " type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="black" className="bi bi-arrow-bar-down bg-light mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" onClick={handleCancelClick}>Annulla</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Stussy')}>Stussy</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Jordan')}>Jordan</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('The North Face')}>The North Face</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Nike')}>Nike</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Travis Scott')}>Travis scott</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Off White')}>Off White</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Antisocial')}>Antisocial</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Chrome')}>Chrome</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Ralph Lauren')}>Ralph Lauren</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Gucci')}>Gucci</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Lacoste')}>Lacoste</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Moncler')}>Moncler</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Supreme')}>Supreme</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Carhartt')}>Carhartt</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => handleBrandClick('Adidas')}>Adidas</a></li>




          </ul>
        </Nav>
      </div>
    </>
  )
}

export default Filtermenu;
