import './Product.css'
import '../pages/pagescomponents/Productmenu.css'
import React, {useState, useRef} from "react";

import {Link} from 'react-router-dom';
import New_product_components from './New_product_components';


function New_product({title, products}){


  const scrollContainer = useRef(null);

  const scrollLeft = () => {
    scrollContainer.current.style.scrollBehavior = 'smooth';
    scrollContainer.current.scrollLeft -= 300;
  };

  const scrollRight = () => {
    scrollContainer.current.style.scrollBehavior = 'smooth';
    scrollContainer.current.scrollLeft += 300;
  };


  
  return (
    <>
        <div className='new_product'>
            <h5 className='ms-4 mb-0'>{title}</h5>
            <div ref={scrollContainer} className='d-flex overflow-scroll h-auto mb-4 ' style={{gap: '30px'}}>
            {[...products].reverse().map((product) => ( // Mappa i dati dei prodotti in ordine inverso
  <Link to={`/Abbigliamento/${product.id}`} style={{textDecoration: 'none', color: 'black'}} key={product.id}>
    <New_product_components key={product.id} modello={product.modello} brand={product.brand} prezzo={product.prezzo} immagine={product.immagine}/>
  </Link>
))}

            </div>
            <div className="scroll-buttons d-flex justify-content-center align-items-center ">
                <svg onClick={scrollLeft} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
                <p className='mb-0'>Scorri!</p>
                <svg onClick={scrollRight} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106 1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
            </div>
        </div>
    </>
)
}

export default New_product;